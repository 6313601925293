<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Comprobantes a pagar</h6>
      <p class="text-body-2">Seleccione los comprobantes a pagar</p>
      <div class="col-md-12" v-if="isViewModal"></div>
      <v-row class="mt-0" v-if="!isViewModal">
        <div class="col-md-8">
          <v-combobox
            :disabled="isViewModal || selected_supplier == null"
            v-model="itemSpent.spent"
            data-vv-as="Comprobante"
            name="spent"
            :item-text="spent_name"
            item-value="id"
            :items="spents"
            label="Comprobante*"
            autocomplete="off"
            v-validate="'required'"
            :loading="loading_spent"
            v-on:change="setImporte()"
          ></v-combobox>
          <span class="red--text">{{ errors.first("spent") }}</span>
        </div>
        <div class="col-md-3">
          <v-text-field
            autocomplete="off"
            name="importe"
            label="Importe*"
            :disabled="isViewModal || itemSpent.spent == null"
            placeholder=""
            v-validate="'decimal'"
            type="number"
            v-model="itemSpent.imp_total"
          />
          <span class="red--text">{{ errors.first("importe") }}</span>
        </div>
      </v-row>
      <v-row class="mt-0">
        <v-col md="12" v-if="!isViewModal">
          <v-btn small color="blue-grey" class="text-white" @click="add()">
            <v-icon big color="green"> add </v-icon>
            <span>Agregar item</span>
          </v-btn>
        </v-col>
        <v-col md="12">
          <v-data-table class="pb-4" :headers="header_spents" :items="item.spents" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
            <template v-slot:item="props">
              <tr>
                <td>
                  {{ props.item.spent.spent_number_label }}
                </td>
                <td>
                  {{ formatUtils.formatPrice(props.item.imp_total) }}
                </td>
                <td>
                  {{ props.item.spent.id == 0 ? "N/A" : formatUtils.formatPrice(props.item.spent.imp_total) }}
                </td>
                <td>
                  {{ props.item.spent.id == 0 ? "N/A" : formatUtils.formatPrice(props.item.spent.pending_to_paid) }}
                </td>

                <td class="text-center">
                  <v-tooltip bottom v-if="!isViewModal">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="red" @click="remove(props.item)"> indeterminate_check_box </v-icon>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col md="12" v-if="!isAssignVoucher">
          <v-chip class="float-right" outlined color="primary">Total pagado: {{ formatUtils.formatPrice(total_spents) }} </v-chip>
        </v-col>

        <v-col md="12" class="pt-0" v-if="isAssignVoucher">
          <v-chip class="float-right" color="primary">Total Pago: {{ formatUtils.formatPrice(item.amount) }} </v-chip>
        </v-col>
        <v-col md="12" class="pt-0" v-if="isAssignVoucher">
          <v-chip class="float-right" outlined color="primary">Total a imputar: {{ formatUtils.formatPrice(total_spents) }} </v-chip>
        </v-col>
        <v-col md="12" class="pt-0" v-if="isAssignVoucher">
          <v-chip class="float-right" outlined color="primary">Saldo pendiente: {{ formatUtils.formatPrice(parseFloat(item.amount) - parseFloat(total_spents)) }} </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import { spentService } from "@/libs/ws/spentService";

export default {
  created() {
    //sacamos el validador del padre, para que solo validar al hijo aca.
    //this.$validator = this.validator;
  },
  components: {},
  props: {
    item: Object,
    validator: Object,
    isViewModal: { type: Boolean, default: false },
    isAssignVoucher: { type: Boolean, default: false },
    spent: { type: Object, default: null },
    open: Boolean,
  },
  data: () => ({
    header_spents: [{ text: "Comprobante" }, { text: "Importe a imputar" }, { text: "Total Gasto" }, { text: "Pendiente de pago" }, { text: "", value: "", sortable: false, class: "bg-grey" }],
    formatUtils: formatUtils,
    spents: [],
    total_spents: 0,
    selected_supplier: null,
    loading_spent: false,
    defaultItemSpent: null,
    itemSpent: {
      id: 0,
      spent: null,
      imp_total: 0,
    },
  }),
  watch: {
    item: {
      handler: function (newValue) {
        if ((newValue.supplier != null && this.selected_supplier == null) || (newValue.supplier != null && this.selected_supplier != null && newValue.supplier.id != this.selected_supplier.id)) {
          this.selected_supplier = newValue.supplier;
          if (!this.isViewModal) this.getVourchers();
        }
      },
      deep: true,
    },
    open: function (newValue) {
      if (this.spent != null) {
        this.itemSpent.spent = this.spent;
        this.itemSpent.imp_total = this.spent.pending_to_paid;
      }
      this.itemSpent = JSON.parse(JSON.stringify(this.defaultItemSpent));
      this.getVourchers();
      this.$validator.reset();
      if (this.isViewModal && this.item.pago_a_cuenta > 0) {
        this.addVoucherACuenta();
      }

      if (!newValue) {
        //CloseModal
        this.spents = [];
        this.total_spents = 0;
        this.selected_supplier = null;
      }
    },
    "item.spents": {
      handler: function (newValue) {
        this.calcTotalSpents();
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultItemSpent = JSON.parse(JSON.stringify(this.itemSpent));
    if (this.spent != null) {
      this.itemSpent.spent = this.spent;
      this.itemSpent.imp_total = this.spent.pending_to_paid;
    }
    if (this.item != null && this.item.supplier != null) {
      this.selected_supplier = this.item.supplier;
      this.getVourchers();
    }
    if (this.isViewModal && this.item.pago_a_cuenta > 0) {
      this.addVoucherACuenta();
    }
    this.calcTotalSpents();
  },
  methods: {
    addVoucherACuenta() {
      this.itemSpent.spent = { id: 0, spent_number_label: "Pago a cuenta", voucher_number: null };
      this.itemSpent.imp_total = this.item.pago_a_cuenta;
      this.item.spents.push(this.itemSpent);
      this.itemSpent = JSON.parse(JSON.stringify(this.defaultItemSpent));
    },
    calcTotalSpents() {
      this.total_spents = 0;
      this.item.spents.forEach((element) => {
        this.total_spents += parseFloat(element.imp_total);
      });
      this.total_spents = this.total_spents.toFixed(2);
    },
    spent_name: (item) => (item.id != 0 ? item.spent_number_label + " (Pendiente de pago $" + item.pending_to_paid + ") " : item.spent_number_label),
    setImporte() {
      if (this.itemSpent.spent != null && this.itemSpent.spent.id != 0) {
        this.itemSpent.imp_total = this.itemSpent.spent.pending_to_paid;
      }
    },
    add() {
      if (this.itemSpent.imp_total <= 0) {
        this.$toast.error("El importe debe ser mayor a 0");
        return;
      }
      if (this.itemSpent.imp_total > this.itemSpent.spent.pending_to_paid && this.itemSpent.spent.id != 0) {
        this.$toast.error("El importe a imputar no puede ser mayor al pendiente de pago");
        return;
      }
      if (this.isAssignVoucher && parseFloat(this.item.amount) < parseFloat(this.total_spents) + parseFloat(this.itemSpent.imp_total)) {
        this.$toast.error("El importe a imputar no puede ser mayor al saldo pendiente");
        return;
      }
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          //remuevo el spent de la lista de spents pendientes
          const index_item = this.spents.findIndex((objeto) => objeto.id === this.itemSpent.spent.id);
          if (index_item > -1) this.spents.splice(index_item, 1);

          //busco si el item ya existe en la lista y le sumo el importe a imputar
          const index_item_spent = this.item.spents.findIndex((objeto) => objeto.spent.id === this.itemSpent.spent.id);
          if (index_item_spent > -1) {
            this.item.spents[index_item_spent].imp_total = parseFloat(this.item.spents[index_item_spent].imp_total) + parseFloat(this.itemSpent.imp_total);
          } else {
            this.item.spents.push(this.itemSpent);
          }
          this.itemSpent = JSON.parse(JSON.stringify(this.defaultItemSpent));
          this.$validator.reset();
        }
      });
    },
    remove(item) {
      const index_item = this.item.spents.indexOf(item);
      if (index_item > -1) this.item.spents.splice(index_item, 1);

      //agrego el spent a la lista de spents pendientes
      if (item.spent.id != 0) {
        this.spents.push(item.spent);
      } else {
        this.spents.unshift(item.spent);
      }
    },
    getVourchers() {
      if (this.item.supplier == null) {
        return;
      }
      this.loading_spent = true;
      var param = {
        supplier: this.item.supplier.id,
      };

      spentService
        .getPendingSpents(param)
        .then((response) => {
          this.spents = response.result;

          if (!this.isAssignVoucher) {
            var pago_a_cuenta = { id: 0, spent_number_label: "Pago a cuenta", voucher_number: null };
            this.spents.unshift(pago_a_cuenta);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading_spent = false;
        });
    },
  },
};
</script>
