var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-4",attrs:{"elevation":"1"}},[_c('v-card-text',[_c('h6',{staticClass:"text-h6 black--text"},[_vm._v("Periodo a imputar")]),(!_vm.isViewModal)?_c('p',{staticClass:"text-body-2"},[_vm._v("Ingrese la fecha de emisión de la factura y la fecha de vencimiento del cobro")]):_vm._e(),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"md":"3"}},[_c('DatePickerEsp',{attrs:{"initToday":true,"label":"Fecha","date":_vm.invoice.cbte_fch,"isRequired":true,"name":"cbte_fch","isViewModal":_vm.isViewModal},on:{"setDate":(date) => {
              _vm.invoice.cbte_fch = date;
            }}})],1),((_vm.concepto_selected != null && _vm.concepto_selected.Id != 1) || _vm.concepto_selected == null)?_c('v-col',{attrs:{"md":"3"}},[_c('DatePickerEsp',{attrs:{"initToday":true,"label":"Periodo desde (Min: -10 días de fecha de emisión)","date":_vm.invoice.cbte_fch_serv_desde,"isRequired":true,"name":"cbte_fch_serv_desde","isViewModal":_vm.isViewModal},on:{"setDate":(date) => {
              _vm.invoice.cbte_fch_serv_desde = date;
            }}})],1):_vm._e(),((_vm.concepto_selected != null && _vm.concepto_selected.Id != 1) || _vm.concepto_selected == null)?_c('v-col',{attrs:{"md":"3"}},[_c('DatePickerEsp',{attrs:{"initToday":true,"label":"Periodo hasta (Max: +10 días de fecha de emisión)","date":_vm.invoice.cbte_fch_serv_hasta,"isRequired":true,"name":"cbte_fch_serv_hasta","isViewModal":_vm.isViewModal},on:{"setDate":(date) => {
              _vm.invoice.cbte_fch_serv_hasta = date;
            }}})],1):_vm._e(),_c('v-col',{attrs:{"md":"3"}},[_c('DatePickerEsp',{attrs:{"initToday":true,"label":"Fecha Vto. Pago","date":_vm.invoice.fch_vto_pago,"isRequired":true,"name":"fch_vto_pago","isViewModal":_vm.isViewModal},on:{"setDate":(date) => {
              _vm.invoice.fch_vto_pago = date;
            }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }