<template>
  <div>
    <Title :pages="[
        { icon: 'file-invoice', path: '/reports/salesman', page: 'DEUDA POR VENDEDOR' },
        { icon: 'circle', page: page_title.toUpperCase() + ': ' + getName() },
      ]">

    </Title>
    
    <v-container fluid>
      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12 elevation-0" style="background: white">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-combobox v-model="client_selected" autocomplete="off" item-text="name" item-value="id" :items="clients" label="Cliente" class="pt-0 mt-0" clearable></v-combobox>
                </div>
              
                <div class="col pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <div class="col-md-8 pb-0 pl-0">
            <v-btn color="blue-grey" small dark :loading="dialog_export" @click="exportFile()" class="mr-2"> Exportar <font-awesome-icon icon="download" /></v-btn>
            <v-chip outlined color="primary"
              >Total pendiente de cobro:&nbsp;<span>{{ formatUtils.formatPrice(total) }}</span></v-chip
            >
          </div>
          <div class="col-md-4 pb-0">
            <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
          </div>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.cbte_fch }}</td>
              <td>
                <span @click="handleModal(props.item.id)" class="redirect-modal">{{ props.item.voucher_number_label }}</span>
              </td>
              <td>{{ props.item.receiver_name }}</td>
              <td>{{ props.item.mon_name }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_factura) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_cobrado_de_factura) }}</td>
              <td class="text-right text-danger">{{ formatUtils.formatPrice(props.item.total_pendiente) }}</td>
              <td v-html="getStatus(props.item.expiration)"></td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { formatUtils } from "@/libs/utils/formatUtils";
import { reportService } from "@/libs/ws/reportService";
import { clientsService } from "@/libs/ws/clientsService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import { salesmanService } from "@/libs/ws/salesmanService";

export default {
  components: { Title},
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      Constant: Constant,
      formatUtils: formatUtils,
      service: reportService,
      page_title: "Cobranzas pendientes",
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Comprobante", align: "left", sortable: true, value: "voucher_number_label" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
        { text: "Total comprobante", align: "center", sortable: true, value: "total_factura" },
        { text: "Total cobrado", align: "center", sortable: true, value: "total_cobrado_de_factura" },
        { text: "Total pendiente", align: "center", sortable: true, value: "total_pendiente" },
        { text: "Estado", align: "center", sortable: false, value: "" },
      ],
      list: [],
      clients: [],
      total: 0,
      total_cobro_a_cuenta: 0,
      salesman_selected: null,
      client_selected: null,
      expirations_status: [
        {
          id: "vencidas",
          name: "Vencidas",
        },
        {
          id: "no_vencidas",
          name: "No Vencidas",
        },
      ],

      mainItemComprobante: {
        id: "",
        creation_date: "",
        cbte_tipo: "",
        emitter: "",
        receiver: "",
        imp_tot_conc: "",
        imp_neto: "",
        imp_total: "",
        dolar_cotiz: null,
      },
      invoice_to_charge: {
        id: 0,
        client: null,
        voucher: null,
        dolar_cotiz: null,
        amount: 0,
        tributes: [],
        charges_payment_methods: [],
        vouchers: [],
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
      },
    };
  },
  mounted() {
    this.loadClients();
    this.loadSalesman();
    this.loadList(this.service);
  },
  methods: {
    loadSalesman() { 
      salesmanService
        .get(parseInt(this.$route.params.salesman_id))
        .then((response) => {
            this.salesman_selected = response.result;
          })
          .catch((e) => {
            console.log("error service salesman get load.error");
            console.log(e);
          })
          .finally(() => {
          });
    },
    loadClients() {
      clientsService.filter().then((response) => {
        this.clients = response.result;
      });
    },
    getName() {
      return this.salesman_selected != null ? this.salesman_selected.name : "-";
    },

    getStatus(expiration) {
      if (expiration > 0) {
        return '<span class="por_vencer">Vence en ' + expiration + " días</span>";
      } else if (expiration == 0) {
        return '<span class="vence_hoy">Vence hoy</span>';
      } else if (expiration < 0) {
        return '<span class="vencida">Vencida hace ' + expiration * -1 + " días</span>";
      } else {
        return "";
      }
    },
    getTotal() {
      this.total = 0;
      if (this.list.length > 0) {
        this.list.forEach((element) => {
          this.total = (parseFloat(this.total) + parseFloat(element.total_pendiente)).toFixed(2);
        });
      }
    },
    getFilterParams() {
      const param = {
        client: this.client_selected != null ? this.client_selected.id : null,
        salesman: this.$route.params.salesman_id,
        ignore_company: true,
      };
      return param;
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .pendingVoucherSalesman(param)
        .then((response) => {
          this.list = response.result;
          //ordeno la lista por el campo expiration
          this.list.sort((a, b) => {
            return a.expiration - b.expiration;
          });

          this.loading = false;
          this.getTotal();
          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.total = 0;
          this.loading = false;
        });
    },
    filter() {
      const param = this.getFilterParams();
      this.loadList(this.service, param);
    },
    exportFile() {
      var param = this.getFilterParams();
      param["export"] = true;

      let fileName = "Comprobantes-Pendientes-Cobro";

      if (this.client_selected != null) {
        fileName += "-" + this.client_selected.name;
      }

      if (this.salesman_selected != null) {
        fileName += "-" + this.salesman_selected.name;
      }
      fileName += ".xlsx";

      this.exportExcel(Constant.SECTION_REPORTS + "/salesman/pending-vouchers", param, fileName);
    },
  },
};
</script>
