<template>
  <div>
    <Title :pages="[{ icon: 'boxes-stacked', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="warehouse_selected" item-text="name" item-value="id" :items="warehouses" label="Deposito" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" @change="selectedCategory()" v-model="category_selected" item-text="name" item-value="id" :items="categories" label="Rubro" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="subcategory_selected" item-text="name" item-value="id" :items="subcategories" label="Sub Rubro" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" @change="selectedProduct()" v-model="product_selected" item-text="name" item-value="id" :items="products" label="Producto" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="product_presentation_selected" item-text="name" item-value="id" :items="presentations" label="Presentación" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="sku_selected" autocomplete="off" class="pt-0 mt-0" label="SKU"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="barcode_selected" autocomplete="off" class="pt-0 mt-0" label="Código de barras"></v-text-field>
                </div>

                <div class="col-md-3 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="blue-grey" class="mr-2" dark :loading="dialog_export" @click="exportFile()"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.warehouse.name }}</td>
              <td>{{ props.item.product_presentation.label }}</td>
              <td>{{ props.item.product_presentation.presentation_type.name }}</td>
              <td>{{ props.item.stock_available }}</td>
              <td>{{ props.item.stock_reserved }}</td>
              <td>{{ props.item.stock_future }}</td>
              <td>{{ formatUtils.formatPrice(props.item.product_presentation.cost) }}</td>
              <td>{{ formatUtils.formatPrice(props.item.stock_available * props.item.product_presentation.cost) }}</td>
              <td>{{ formatUtils.formatPrice(props.item.product_presentation.base_price) }}</td>
              <td>{{ formatUtils.formatPrice(props.item.stock_available * props.item.product_presentation.base_price) }}</td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { stockService } from "@/libs/ws/stockService";

import GenericMixin from "@/mixins/GenericMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import Title from "@/components/Title.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import { formatUtils } from "@/libs/utils/formatUtils";

export default {
  components: { Title, Modal },
  mixins: [GenericMixin, RolMixin, ExportMixin, SettingsMixin],
  data() {
    return {
      formatUtils: formatUtils,
      parent: this,
      service: stockService,
      page_title: "Inventario",
      modal_title: "Movimiento de Stock",
      mainItem: {
        id: "",
        movement_type: "M",
        warehouse_origin: null,
        warehouse_destination: null,
        product_presentation: null,
        stock_type: "A",
        stock_updated: 0,
      },
      defaultItem: null,
      headers: [
        {
          text: "Depósito",
          align: "left",
          sortable: true,
          value: "warehouse.name",
        },
        {
          text: "Producto",
          align: "left",
          sortable: true,
          value: "product_presentation.label",
        },
        {
          text: "Tipo de presentacion",
          align: "left",
          sortable: true,
          value: "product_presentation.presentation_type.name",
        },
        {
          text: "Stock Disponible",
          align: "left",
          sortable: true,
          value: "stock_available",
        },
        {
          text: "Stock Reservado",
          align: "left",
          sortable: true,
          value: "stock_reserved",
        },
        {
          text: "Stock Futuro",
          align: "left",
          sortable: true,
          value: "stock_future",
        },
        {
          text: "Costo Unitario s/IVA",
          align: "left",
          sortable: true,
          value: "product_presentation.cost",
        },
        {
          text: "Costo Total s/IVA",
          align: "left",
          sortable: true,
        },
        {
          text: "Precio Unitario s/IVA",
          align: "left",
          sortable: true,
          value: "product_presentation.base_price",
        },
        {
          text: "Valor Total s/IVA",
          align: "left",
          sortable: true,
        },
      ],
      list: [],
      dialog_delete: false,

      warehouses: [],
      categories: [],
      subcategories: [],
      products: [],
      presentations: [],

      warehouse_selected: null,
      category_selected: null,
      subcategory_selected: null,
      product_selected: null,
      product_presentation_selected: null,
      sku_selected: null,
      barcode_selected: null,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service);
  },
  methods: {
    label(item) {
      if (this.isProductCompany()) return item.label;
      return item.name;
    },
    loadComboData() {
      //Warehouses
      generalService.setSection(Constant.SECTION_WAREHOUSES);
      generalService
        .filter({ enabled: true })
        .then((response) => {
          this.warehouses = response.result;
          //Auto select default
          this.warehouses.forEach((element) => {
            if (element.default_warehouse) {
              this.warehouse_selected = element;
            }
          });
        })
        .catch((e) => {
          console.log("categories.filter error");
          console.log(e);
        });

      //Categories
      generalService.setSection(Constant.SECTION_CATEGORIES);
      generalService
        .filter()
        .then((response) => {
          this.categories = response.result;
        })
        .catch((e) => {
          console.log("categories.filter error");
          console.log(e);
        });

      //Products
      generalService.setSection(Constant.SECTION_PRODUCTS);
      generalService
        .filter()
        .then((response) => {
          this.products = response.result;
        })
        .catch((e) => {
          console.log("products.filter error");
          console.log(e);
        });
    },
    selectedCategory() {
      if (this.category_selected) {
        generalService.setSection(Constant.SECTION_SUBCATEGORIES);
        generalService
          .filter({ category: this.category_selected.id })
          .then((response) => {
            this.subcategories = response.result;
          })
          .catch((e) => {
            console.log("subcategories.filter error");
            console.log(e);
          });
      }
    },
    selectedProduct() {
      if (this.product_selected) {
        generalService.setSection(Constant.SECTION_PRESENTATIONS);
        generalService
          .filter({ product: this.product_selected.id })
          .then((response) => {
            this.presentations = response.result;
          })
          .catch((e) => {
            console.log("presentations.filter error");
            console.log(e);
          });
      }
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    getFilterParams() {
      const param = {
        warehouse: this.warehouse_selected,
        category: this.category_selected,
        subcategory: this.subcategory_selected,
        product: this.product_selected,
        product_presentation: this.product_presentation_selected,
        sku: this.sku_selected,
        barcode: this.barcode_selected,
      };
      return param;
    },
    filter() {
      this.loadList(this.service);
    },
    exportFile() {
      var param = this.getFilterParams();
      param.export = true;
      this.exportExcel(Constant.SECTION_STOCKS + "/list", param, "Stock.xlsx");
    },
  },
};
</script>
