import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_PURCHASE_ORDER;

const IVA_N_A = 0;
const IVA_NG = 1; //NO GRAVADO intenro
const IVA_E = 2; //NO GRAVADO intenro
const IVA_0 = 3;
const IVA_2_5 = 9;
const IVA_5 = 8;
const IVA_10_5 = 4;
const IVA_21 = 5;
const IVA_27 = 6;

function NoIVAList() {
  return [IVA_NG, IVA_E, IVA_0, IVA_N_A];
}

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(params) {
  return wsService.make_post_request(section + "/create", params);
}

function update(params) {
  return wsService.make_post_request(section + "/update", params);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function get_next_number(receiver_id, pto_vta) {
  return wsService.make_post_request(section + "/get_next_number", { receiver_id: receiver_id, pto_vta: pto_vta });
}

function cancel_purchase_order(param) {
  return wsService.make_post_request(section + "/cancel_purchase_order", param);
}

function end_purchase_order(param) {
  return wsService.make_post_request(section + "/end_purchase_order", param);
}

function print(purchase_order_id) {
  return wsService.make_post_request(
    section + "/print/" + purchase_order_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

function export_filter(params) {
  return wsService.make_post_request(section + "/export", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function get(id) {
  return wsService.make_get_request(section + "/get/" + id);
}

function get_comprobantes(param) {
  return wsService.make_post_request(section + "/get_comprobantes", param);
}

function list_states() {
  return wsService.make_get_request(section + "/list_states/");
}

export const purchaseOrderService = {
  filter,
  create,
  update,
  remove,
  export_filter,
  NoIVAList,
  get,
  get_next_number,
  cancel_purchase_order,
  end_purchase_order,
  get_comprobantes,
  print,
  list_states,
  IVA_0,
  IVA_10_5,
  IVA_21,
  IVA_27,
  IVA_2_5,
  IVA_5,
  IVA_E,
  IVA_NG,
};
