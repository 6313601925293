<template>
  <div class="login">
    <div class="row bg justify-content-center align-items-center">
      <div class="col-md-4" style="max-width: 460px">
        <v-card :dark="true" :flat="true" class="rounded-1 elevation-5">
          <v-card-title class="justify-content-center">
            <v-img :src="require('@/assets/images/syscore_logo.svg')" contain max-width="225" class="mt-3" />
          </v-card-title>
          <v-card-text class="padding">
            <v-form>
              <p v-if="login_error != ''" class="red--text">Los datos ingresados son incorrectos. Vuelve a intentarlo</p>
              <v-text-field label="Email" v-model="email" prepend-icon="mdi-account" v-on:keyup.enter="auth" />
              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                :type="show ? 'text' : 'password'"
                label="Password"
                v-model="password"
                prepend-icon="mdi-lock"
                v-on:keyup.enter="auth"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="padding">
            <v-btn class="w-100 mb-8 btn rounded-0" v-on:click="auth" :loading="loading">Login</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { authService } from "@/libs/ws/authService";
import Constant from "@/constants/sections";
export default {
  name: "App",

  components: {},

  data: () => ({
    login_error: "",
    email: "",
    password: "",
    show: false,
    loading: false,
  }),

  methods: {
    auth: function (event) {
      this.loading = true;
      authService
        .login(this.email, this.password)
        .then((response) => {
          if (!response.status) {
            this.login_error = response.msg;
          } else {
            localStorage.setItem("token", response.result.token);
            localStorage.setItem("expire_in", response.result.expire_in);
            localStorage.setItem("email", response.result.email);
            localStorage.setItem("name", response.result.name);
            const encryptedText = this.CryptoJS.AES.encrypt(JSON.stringify(response.result.access), Constant.SECRET_PHRASE).toString();

            localStorage.setItem("access", encryptedText);
            localStorage.setItem("user_type", response.result.user_type);
            localStorage.setItem("company_user_id", response.result.company_user_id);
            localStorage.setItem("app_object", JSON.stringify(response.result.app_object)); //Representa el id segun el tipo de usuario: Client o Employee
            localStorage.setItem("settings", JSON.stringify(response.result.settings));
            //this.$router.go("/"); NO FUNCIONA EN SAFARI
            window.location.reload();
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
};
</script>
