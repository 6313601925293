<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-card v-if="list_access || 1 == 1">
        <div class="row mb-2 mt-0">
          <div class="col-md-12">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox v-model="emitter_selected" autocomplete="off" item-text="name" item-value="id" :items="emitters" label="Emisor/Receptor*" class="pt-0 mt-0" clearable></v-combobox>
                </div>
                <!-- <div class="col-md-3 pt-0" style="display: flex; justify-content: end">
                    <v-btn color="info" class="" @click="filter">Generar Exportar</v-btn>
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card v-if="list_access || 1 == 1">
        <v-data-table :headers="headers" :items="list" item-key="id" :loading="loading" :mobile-breakpoint="0" loading-text="Cargando... Por favor espere">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td class="text-right justify-content-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="download(item)"> download </v-icon>
                  </template>
                  <span>Descargar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <v-alert :value="true" color="error" icon="warning"> No se encontraron resultados para "{{ search }}". </v-alert>
        </v-data-table>
      </v-card>
      <DialogLoading :dialog="loading_download" text="Descargando archivo" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import { emmiterService } from "@/libs/ws/emmiterService";

import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import { reportService } from "../../libs/ws/reportService";
import DialogLoading from "@/components/DialogLoading.vue";

export default {
  components: { Title, DialogLoading },
  mixins: [GenericMixin, RolMixin, ExportMixin, SettingsMixin],
  data() {
    return {
      open_modal: false,
      selected_item: null,
      modal_title: "",
      Constant: Constant,
      formatUtils: formatUtils,
      service: generalService,
      page_title: "Libro IVA Digital",
      headers: [
        {
          text: "Nombre de archivo",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Accion",
          align: "right",
          sortable: true,
          value: "",
        },
      ],
      list: [
        {
          id: 1,
          name: "Libro IVA Ventas Comprobantes",
          value: "ventas-cbte",
        },
        {
          id: 2,
          name: "Libro IVA Ventas Alicuotas",
          value: "ventas-alicuotas",
        },
        {
          id: 3,
          name: "Libro IVA Compras Comprobantes",
          value: "compras-cbte",
        },
        {
          id: 4,
          name: "Libro IVA Compras Alicuotas",
          value: "compras-alicuotas",
        },
      ],
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      desde_selected_open: false,
      hasta_selected_open: false,
      emitter_selected: null,
      emitters: [],
      loading_download: false,
    };
  },
  mounted() {
    this.loadEmitters();
  },
  methods: {
    filter() {
      // this.loadList(this.service, this.getFilterParams());
    },
    download(item) {
      if (!this.emitter_selected) {
        this.$toast.error("Debe seleccionar un emisor");
        return;
      }

      this.loading_download = true;

      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        emitter: this.emitter_selected.id,
        libro_iva: item.value,
      };

      reportService
        .ivaLibroDigital(param)
        .then((response) => {
          try {
            //Transformo a string la respuesta para poder enviar mensajes de error.
            let result = JSON.parse(this.arrayBufferToString(response.data));
            if (!result.status) {
              this.toast.error(result.msg);
            }
          } catch (e) {
            const blob = new Blob([response.data], { type: "text/plain" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.target = "_blank";
            link.download = "IVA-" + item.value + ".txt"; // Nombre del archivo
            link.click();
            window.URL.revokeObjectURL(link.href); // Limpiar URL del objeto
          }
        })
        .catch((e) => console.log("service iva libro digital error", e))
        .finally(() => {
          this.loading_download = false;
        });
    },
    getFilterParams(export_option = "") {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        emitter: this.emitter_selected != null ? this.emitter_selected.id : null,
        export_option: export_option,
      };
      return param;
    },
    loadEmitters() {
      emmiterService.filter({ enabled: true }).then((response) => {
        this.emitters = response.result;
      });
    },
    arrayBufferToString(buffer) {
      var arr = new Uint8Array(buffer);
      var str = String.fromCharCode.apply(String, arr);
      if (/[\u0080-\uffff]/.test(str)) {
        throw new Error("this string seems to contain (still encoded) multibytes");
      }
      return str;
    },
  },
};
</script>
