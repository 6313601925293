<template>
  <div>
    <v-dialog v-model="open" persistent max-width="900">
      <v-card>
        <v-card-title class="headline">{{ getTitle() }}</v-card-title>

        <v-card-text>
          <v-btn :disabled="!can_disassociate || list.length > 0" small color="blue-grey" class="pl-4 text-white mt-3 mb-3" @click="dialog_add_voucher = true">
            <v-icon big color="green"> add </v-icon>
            <span>Asociar comprobante</span>
          </v-btn>
          <v-data-table
            :headers="headers"
            :items="list"
            :options="{
              descending: true,
              itemsPerPage: 15,
            }"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 50, -1],
            }"
          >
            <template v-slot:item="props">
              <tr>
                <td>{{ getAsoc(props.item).creation_date }}</td>
                <td>{{ getAsoc(props.item).cbte_tipo_name }}</td>
                <td>{{ getAsoc(props.item).voucher_number != null ? getAsoc(props.item).voucher_number.toString().padStart(8, "0") : "-" }}</td>
                <td>{{ getEmitter(props.item) }}</td>
                <td>{{ getAsoc(props.item).receiver.name }}</td>
                <td>{{ getAsoc(props.item).mon_name }}</td>
                <td>{{ formatUtils.formatPrice(getAsoc(props.item).imp_total) }}</td>
                <td class="text-center">
                  <div v-if="can_disassociate">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="red" class="mr-2" @click="openDisassociate(props.item)"> close </v-icon>
                      </template>
                      <span>Desasociar</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                {{ "Busqueda sin resultados - " + search }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="close()"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogAssociateVoucher
      :isSpent="isSpent"
      :service="service"
      @associateVoucher="associateVoucher"
      :open="dialog_add_voucher"
      @cancel="dialog_add_voucher = false"
      @reload="$emit('reload')"
      :invoice="item"
    />

    <DialogYesNo
      :open="dialog_disassociate"
      nameCancel="Cancelar"
      nameAccept="Desasociar"
      title="Desasociar comprobante"
      message="¿Esta seguro que desea desasociar el comprobante?"
      @cancel="closeDisassociate"
      @accept="handleDisassociate"
      :maxWidth="500"
      :loading="loading_disassociate"
    />
  </div>
</template>
<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import DialogYesNo from "@/components/DialogYesNo";
import DialogAssociateVoucher from "@/components/DialogAssociateVoucher";
export default {
  components: { DialogYesNo, DialogAssociateVoucher },
  props: {
    open: Boolean,
    cancel: Function,
    item: Object,
    can_disassociate: { type: Boolean, default: false },
    service: { type: Object, default: null },
    reload: { type: Function, default: null },
    isSpent: { type: Boolean, default: false },
  },
  data: () => ({
    formatUtils: formatUtils,
    list: [],
    headers: [
      { text: "Fecha", align: "left", sortable: true, value: "creation_date" },
      { text: "Comprobante", align: "left", sortable: true, value: "cbte_tipo_name" },
      { text: "Nº Comprobante", align: "left", sortable: true, value: "voucher_number" },
      { text: "Emisor", align: "left", sortable: true, value: "emitter.name" },
      { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
      { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
      { text: "Total", align: "left", sortable: true, value: "imp_total" },
      { text: "Accion", align: "left" },
    ],
    dialog_disassociate: false,
    loading_disassociate: false,
    item_disassociate: null,
    dialog_add_voucher: false,
  }),
  watch: {
    open: function (val) {
      if (val) {
        if (this.item.cbtes_asoc.length > 0) {
          this.list = this.item.cbtes_asoc;
        } else {
          this.list = [];
        }
      }
    },
  },
  mounted() {},
  methods: {
    associateVoucher(cbtesAsociados) {
      this.list = cbtesAsociados;
    },
    close() {
      this.$emit("cancel");
    },
    getAsoc(item) {
      if (!this.isSpent) return item.voucher_asoc;
      return item.spent_asoc;
    },
    getEmitter(item) {
      if (!this.isSpent) return item.voucher_asoc.emitter.name;
      return item.spent_asoc.supplier.name;
    },
    getTitle() {
      if (this.item != null) {
        const voucher = this.item.voucher_number != null ? this.item.voucher_number.toString().padStart(8, "0") : "-";
        return "Comprobantes asociados de " + this.item.voucher_number_label; //this.item.cbte_tipo_name + " - Nº " + this.item.pto_vta.toString().padStart(4,"0") + "-" + voucher;
      }
      return "";
    },
    openDisassociate(item) {
      this.item_disassociate = JSON.parse(JSON.stringify(item));
      this.dialog_disassociate = true;
    },
    closeDisassociate() {
      this.item_disassociate = null;
      this.dialog_disassociate = false;
    },
    handleDisassociate() {
      if (this.can_disassociate == false || this.service == null) return;

      if (this.item_disassociate != null) {
        this.loading_disassociate = true;
        this.service
          .disassociate(
            this.isSpent ? this.item_disassociate.spent : this.item_disassociate.voucher, //*Ya es un ID
            this.getAsoc(this.item_disassociate).id
          )
          .then((response) => {
            if (response.status) {
              this.$toast.success("Comprobante desasociado correctamente");
              this.item_disassociate = null;
              this.dialog_disassociate = false;
              this.$emit("reload");
              this.$emit("cancel");
            } else {
              this.$toast.error(response.msg);
            }
          })
          .catch((e) => {
            this.$toast.error("Error al desasociar el comprobante");
          })
          .finally(() => {
            this.loading_disassociate = false;
          });
      }
    },
  },
};
</script>
