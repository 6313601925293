import { wsService } from "./wsService";
import sections from "@/constants/sections";
import axios from "axios";

const api_url = process.env.VUE_APP_API_URL;
const section = sections.SECTION_REPORTS;

function pendingVoucherClient(param = { enable: true }) {
  return wsService.make_post_request(section + "/client/pending-vouchers", param);
}

function pendingVoucherSupplier(param = { enable: true }) {
  return wsService.make_post_request(section + "/supplier/pending-vouchers", param);
}

function pendingVoucherSalesman(param = { enable: true }) {
  return wsService.make_post_request(section + "/salesman/pending-vouchers", param);
}
function getSalesmanCommission(param = { enable: true }) {
  return wsService.make_post_request(section + "/sales/salesman-commissions", param);
}

function getRetentions(param = { enable: true }) {
  return wsService.make_post_request(section + "/retentions", param);
}

function getPerceptions(param = { enable: true }) {
  return wsService.make_post_request(section + "/perceptions", param);
}

async function ivaLibroDigital(param = {}) {
  const token = localStorage.getItem("token");
  console.log("token", token);
  try {
    return await axios.post(api_url + section + "/iva-libro-digital", param, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + token,
      },
    });
  } catch (error) {
    console.error("Network Error:", error);
  }
}
function export_filter(params) {
  return wsService.make_post_request(section + "/export/detailing-subcost", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}
export const reportService = {
  pendingVoucherClient,
  pendingVoucherSupplier,
  getSalesmanCommission,
  getRetentions,
  getPerceptions,
  ivaLibroDigital,
  export_filter,
  pendingVoucherSalesman
};
