<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <div class="content-main">
        <v-tabs v-model="tab">
          <v-tab v-for="item in items" :key="item.key">
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="0">
            <VentasPorPeriodo></VentasPorPeriodo>
          </v-tab-item>
          <v-tab-item key="1">
            <VentasPorAnio></VentasPorAnio>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
import VentasPorPeriodo from "./VentasPorPeriodo.vue";
import VentasPorAnio from "./VentasPorAnio.vue";
import Title from "@/components/Title.vue";

export default {
  mixins: [],
  components: { Title, VentasPorAnio, VentasPorPeriodo },
  data() {
    return {
      page_title: "Reporte de Ventas",
      mainItem: {
        id: 0,
        name: "",
      },
      defaultItem: {
        id: 0,
        name: "",
      },
      tab: 0,
      list: [],
      items: [
        { tab: "Por Periodo", key: 0 },
        { tab: "Por Año", key: 1 },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
