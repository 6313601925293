<template>
  <div>
    <v-dialog v-model="open" max-width="1000" @click:outside="close">
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-card-title class="headline mb-4">{{ title }}</v-card-title>

        <v-card-text>
          <div class="row">
            <div class="col-md-12">
              <v-data-table
                :headers="headers"
                :items="list"
                :loading="loading"
                loading-text="Cargando..."
                :options="{
                  descending: true,
                  itemsPerPage: 15,
                }"
              >
                <template v-slot:item="props">
                  <tr>
                    <td>{{ props.item.cbte_fch }}</td>
                    <td>
                      <span @click="handleModal(props.item.id)" class="redirect-modal">{{ props.item.voucher_number_label }}</span>
                    </td>
                    <td>{{ props.item.emitter.name }}</td>
                    <td>{{ props.item.receiver.name }}</td>
                    <td>{{ props.item.mon_name }}</td>
                    <td class="text-right">{{ formatUtils.formatPrice(props.item.total_ars) }}</td>
                    <td>{{ props.item.state.name }}</td>
                  </tr>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning">
                    {{ "Busqueda sin resultados " }}
                  </v-alert>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="close()"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogLoading :dialog="loading_view_comprobante" text="Cargando..." />
    <DialogGenerarComprobante title="Ver Comprobante" :isViewModal="true" :open="dialog_view_comprobante" @closeDialog="updateDialogComprobante" :item="mainItemComprobante" />
  </div>
</template>

<script>
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import { saleOrderService } from "@/libs/ws/saleOrderService";
import { formatUtils } from "@/libs/utils/formatUtils";
import { vouchersService } from "@/libs/ws/vouchersService";
import DialogGenerarComprobante from "@/views/Billing/Vouchers/DialogGenerarComprobante.vue";
import DialogLoading from "@/components/DialogLoading.vue";

export default {
  components: { DialogGenerarComprobante, DialogLoading },
  mixins: [SettingsMixin],
  props: {
    title: String,
    open: Boolean,
    sale_order_id: Number,
  },
  data: () => ({
    list: [],
    formatUtils: formatUtils,
    defaultItem: null,
    loading: false,
    headers: [
      { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
      { text: "Comprobante", align: "left", sortable: true, value: "voucher_number" },
      { text: "Emisor", align: "left", sortable: true, value: "emitter.name" },
      { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
      { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
      { text: "Total ARS", align: "left", sortable: true, value: "imp_total" },
      { text: "Estado", align: "left", sortable: true, value: "state" },
    ],
    mainItemComprobante: {
      id: "",
      creation_date: "",
      cbte_tipo: "",
      emitter: "",
      receiver: "",
      imp_tot_conc: "",
      imp_neto: "",
      imp_total: "",
      dolar_cotiz: null,
    },
    loading_view_comprobante: false,
    dialog_view_comprobante: false,
  }),
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  watch: {
    open: function (val) {
      if (val) {
        this.loadList();
      }
    },
  },
  mounted() {},
  methods: {
    updateDialogComprobante() {
      this.dialog_view_comprobante = false;
    },
    close() {
      this.list = [];
      this.$emit("closeDialog");
    },

    handleModal(id) {
      this.loading_view_comprobante = true;
      vouchersService
        .get(parseInt(id))
        .then((response) => {
          this.mainItemComprobante = response.result;
          this.loading_view_comprobante = false;
          this.dialog_view_comprobante = true;
        })
        .catch((e) => {
          console.log("error service voucher get load.error");
          console.log(e);
          this.loading_view_comprobante = false;
        })
        .finally(() => {
          this.loading_view_comprobante = false;
        });
    },
    loadList() {
      this.loading = true;

      const param = {
        sale_order_id: this.sale_order_id,
      };

      this.open_filter = false;
      saleOrderService
        .get_comprobantes(param)
        .then((response) => {
          this.list = response;
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
