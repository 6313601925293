<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"> </Title>

    <v-container fluid>
      <DialogLoading :dialog="loading_view_charge" text="Cargando..." />

      <ModalCharged v-if="update_access" :voucher="invoice_to_charge" :openFromVoucher="true" :formBtn="formBtn" :dialog="dialog_charged" @closeDialog="closeChargeDialog" />

      <DialogLoading :dialog="loading_view_comprobante" text="Cargando..." />

      <DialogGenerarComprobante title="Ver Comprobante" :isViewModal="true" :open="dialog_view_comprobante" @closeDialog="updateDialogComprobante" :item="mainItemComprobante" />

      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12 elevation-0" style="background: white">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-combobox v-model="client_selected" autocomplete="off" item-text="name" item-value="id" :items="clients" label="Cliente" class="pt-0 mt-0" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox v-model="salesman_selected" autocomplete="off" item-text="name" item-value="id" :items="vendedores" label="Vendedor" class="pt-0 mt-0" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-select v-model="expiration_status" :items="expirations_status" item-text="name" item-value="id" label="Estado" class="pt-0 mt-0" clearable></v-select>
                </div>
                <div class="col pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <div class="col-md-8 pb-0 pl-0">
            <v-btn color="blue-grey" small dark :loading="dialog_export" @click="exportFile()" class="mr-2"> Exportar <font-awesome-icon icon="download" /></v-btn>
            <v-chip outlined color="primary"
              >Total pendiente de cobro:&nbsp;<span>{{ formatUtils.formatPrice(total) }}</span></v-chip
            >
          </div>
          <div class="col-md-4 pb-0">
            <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
          </div>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.cbte_fch }}</td>
              <td>
                <span @click="handleModal(props.item.id)" class="redirect-modal">{{ props.item.voucher_number_label }}</span>
              </td>
              <td>{{ props.item.receiver_name }}</td>
              <td>{{ props.item.mon_name }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_factura) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_cobrado_de_factura) }}</td>
              <td class="text-right text-danger">{{ formatUtils.formatPrice(props.item.total_pendiente) }}</td>
              <td v-html="getStatus(props.item.expiration)"></td>
              <td class="text-right px-0 pr-3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="green" class="mr-2" @click="openPayment(props.item)"> payments </v-icon>
                  </template>
                  <span>Cobrar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { formatUtils } from "@/libs/utils/formatUtils";
import { reportService } from "@/libs/ws/reportService";
import { chargeService } from "@/libs/ws/chargeService";
import { vouchersService } from "@/libs/ws/vouchersService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import { clientsService } from "@/libs/ws/clientsService";
import DialogGenerarComprobante from "@/views/Billing/Vouchers/DialogGenerarComprobante.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import ModalCharged from "@/views/Charges/ModalCharged.vue";
import { salesmanService } from "@/libs/ws/salesmanService";

export default {
  components: { Title, DialogGenerarComprobante, DialogLoading, ModalCharged },
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      Constant: Constant,
      formatUtils: formatUtils,
      service: reportService,
      page_title: "Cobranzas pendientes",
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Comprobante", align: "left", sortable: true, value: "voucher_number_label" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
        { text: "Total comprobante", align: "center", sortable: true, value: "total_factura" },
        { text: "Total cobrado", align: "center", sortable: true, value: "total_cobrado_de_factura" },
        { text: "Total pendiente", align: "center", sortable: true, value: "total_pendiente" },
        { text: "Estado", align: "center", sortable: false, value: "" },
        { text: "Accion", align: "right", sortable: false, value: "" },
      ],
      list: [],
      total: 0,
      total_cobro_a_cuenta: 0,
      clients: [],
      vendedores: [],
      client_selected: null,
      salesman_selected: null,
      expiration_status: null,
      dialog_view_comprobante: false,
      loading_view_comprobante: false,
      expirations_status: [
        {
          id: "vencidas",
          name: "Vencidas",
        },
        {
          id: "no_vencidas",
          name: "No Vencidas",
        },
      ],

      mainItemComprobante: {
        id: "",
        creation_date: "",
        cbte_tipo: "",
        emitter: "",
        receiver: "",
        imp_tot_conc: "",
        imp_neto: "",
        imp_total: "",
        dolar_cotiz: null,
      },
      invoice_to_charge: {
        id: 0,
        client: null,
        voucher: null,
        dolar_cotiz: null,
        amount: 0,
        tributes: [],
        charges_payment_methods: [],
        vouchers: [],
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
      },
      dialog_charged: false,
      loading_view_charge: false,
    };
  },
  mounted() {
    this.loadClients();
    this.loadVendedores();
    this.loadList(this.service);
  },
  methods: {
    loadVendedores() {
      salesmanService
        .filter({ enabled: true })
        .then((response) => {
          this.vendedores = response.result;
        })
        .catch((e) => {});
    },
    openPayment(item) {
      console.log("item", item);

      this.loading_view_charge = true;
      vouchersService
        .get(parseInt(item.id))
        .then((response) => {
          this.invoice_to_charge = response.result;
          this.loading_view_charge = false;
          this.dialog_charged = true;
        })
        .catch((e) => {
          console.log("error service comprobante de cobro get load.error");
          console.log(e);
          this.loading_view_charge = false;
        })
        .finally(() => {
          this.loading_view_charge = false;
        });
    },
    closeChargeDialog() {
      this.invoice_to_charge = null;
      this.dialog_charged = false;
      this.loadList(this.service, this.getFilterParams());
    },
    updateDialogComprobante() {
      this.dialog_view_comprobante = false;
    },
    handleModal(id) {
      this.loading_view_comprobante = true;
      vouchersService
        .get(parseInt(id))
        .then((response) => {
          this.mainItemComprobante = response.result;
          this.loading_view_comprobante = false;
          this.dialog_view_comprobante = true;
        })
        .catch((e) => {
          console.log("error service voucher get load.error");
          console.log(e);
          this.loading_view_comprobante = false;
        })
        .finally(() => {
          this.loading_view_comprobante = false;
        });
    },
    loadClients() {
      clientsService.filter().then((response) => {
        this.clients = response.result;
      });
    },
    getStatus(expiration) {
      if (expiration > 0) {
        return '<span class="por_vencer">Vence en ' + expiration + " días</span>";
      } else if (expiration == 0) {
        return '<span class="vence_hoy">Vence hoy</span>';
      } else if (expiration < 0) {
        return '<span class="vencida">Vencida hace ' + expiration * -1 + " días</span>";
      } else {
        return "";
      }
    },
    getTotal() {
      this.total = 0;
      if (this.list.length > 0) {
        this.list.forEach((element) => {
          this.total = (parseFloat(this.total) + parseFloat(element.total_pendiente)).toFixed(2);
        });
      }
    },
    getFilterParams() {
      const param = {
        client: this.client_selected != null ? this.client_selected.id : null,
        salesman: this.salesman_selected != null ? this.salesman_selected.id : null,
        status: this.expiration_status != null ? this.expiration_status : null,
      };
      return param;
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .pendingVoucherClient(param)
        .then((response) => {
          this.list = response.result;
          //ordeno la lista por el campo expiration
          this.list.sort((a, b) => {
            return a.expiration - b.expiration;
          });

          this.loading = false;
          this.getTotal();
          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.total = 0;
          this.loading = false;
        });
    },
    filter() {
      const param = this.getFilterParams();
      this.loadList(this.service, param);
    },
    exportFile() {
      var param = this.getFilterParams();
      param["export"] = true;

      let fileName = "Comprobantes-Pendientes-Cobro";

      if (this.client_selected != null) {
        fileName += "-" + this.client_selected.name;
      }

      if (this.salesman_selected != null) {
        fileName += "-" + this.salesman_selected.name;
      }
      fileName += ".xlsx";

      this.exportExcel(Constant.SECTION_REPORTS + "/client/pending-vouchers", param, fileName);
    },
  },
};
</script>
