<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Escaner IA<v-chip class="ml-3" color="primary"> Nuevo </v-chip> <v-chip outlined class="ml-1"> Beta Version </v-chip></h6>
      <p class="text-body-2">Procesa tu comprobante con Inteligencia Artificial y carga los datos en forma automatica</p>
      <p class="caption">Solo admite formato PDF</p>
      <v-row>
        <v-col md="4">
          <v-file-input filled dense label="Seleccionar comprobante" name="invoice_file" ref="invoice_file_ref" v-model="invoice_file"></v-file-input>
        </v-col>
        <v-col md="2">
          <v-btn @click="readInvoice()" class="mt-3 text-white" color="blue-grey" small :loading="loading"> Procesar</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { spentService } from "@/libs/ws/spentService";
export default {
  components: {},
  props: {
    setInvoice: Function,
  },
  data: () => ({
    invoice: null,
    invoice_file: null,
    loading: false,
  }),
  watch: {},
  mounted() {},
  methods: {
    readInvoice() {
      this.loading = true;
      spentService
        .readInvoice()
        .then((response) => {
          if (response.status) {
            this.$emit("setInvoice", response.result);
          } else {
            this.$toast.error(response.msg, "Error");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
