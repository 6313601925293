<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-card v-if="list_access || 1 == 1">
        <v-card-title>
            <template>
              <v-btn color="blue-grey" class="mt-3" small dark :loading="dialog_export" @click="exportFile"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>
            </template>

          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.salesman }}</td>
              <td>{{ formatUtils.formatPrice(props.item.total_pending)  }}</td>

              <td class="text-center px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue-grey" class="mr-2" @click="goToBalances(props.item)"> description </v-icon>
                  </template>
                  <span>Ver Detalle Cta. Cte.</span>
                </v-tooltip>

               
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

     
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import { clientsService } from "@/libs/ws/clientsService";
import { emmiterService } from "@/libs/ws/emmiterService";

import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";

export default {
  components: { Title },
  mixins: [GenericMixin, RolMixin, ExportMixin, SettingsMixin],
  data() {
    return {
      modal_title: "",
      Constant: Constant,
      formatUtils: formatUtils,
      service: generalService,
      page_title: "Deuda por vendedor",
      headers: [
        {
          text: "Vendedor",
          align: "left",
          sortable: true,
          value: "salesman",
        },
        {
          text: "Total Adeudado",
          align: "left",
          sortable: true,
          value: "total_pending",
        },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
    };
  },
  mounted() {
    this.loadList(this.service);
  },
  methods: {
    goToBalances(item) {
      this.$router.push("/" +Constant.SECTION_REPORT_SALESMAN + "/pending-vouchers/" + item.id);
    },
    getFilterParams(export_option = "") {
      const param = {
        export_option: export_option,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .query(param, Constant.SECTION_REPORT_SALESMAN)
        .then((response) => {
          this.list = response.result;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    exportFile(option) {
      var param = this.getFilterParams(option);
      param["export"] = true;

      var filename = "Deuda-por-vendedor.xlsx";
     
      this.exportExcel(Constant.SECTION_REPORT_SALESMAN, param, filename);
    },
  },
};
</script>
