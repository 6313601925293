import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sidebar: {
      open: false,
      drawer: false,
      mini: false,
      expand_on_hover: false,
    },
    config: {
      items_per_page: 10,
    },
    client: {
      pagination: {
        page: 1,
      },
      filter: {
        selected_status: true,
        salesman_selected: null,
        client_type_selected: null,
        client_zone_selected: null,
        search: ""
      }
    },
    supplier: {
      pagination: {
        page: 1,
      },
      filter: {
        selected_status: true,
        search: ""
      }
    },
    cta_cte_client: {
      pagination: {
        page: 1,
      },
      filter: {
        salesman_selected: null,
        search: ""
      }
    },
    cta_cte_supplier: {
      pagination: {
        page: 1,
      },
      filter: {
        search: ""
      }
    },
  },
  mutations: {
    setOpenSidebar: function (state, payload) {
      if (payload.open != undefined) state.sidebar.open = payload.open;

      if (payload.drawer != undefined) state.sidebar.drawer = payload.drawer;

      if (payload.mini != undefined) state.sidebar.mini = payload.mini;
      if (payload.expand_on_hover != undefined) state.sidebar.expand_on_hover = payload.expand_on_hover;
    },
    setCurrentPageClient: function (state, pagination) {
      state.client.pagination.page = pagination;
    },
    setFilterClient: function (state,payload) { 
      if (payload.selected_status != undefined) {
        state.client.filter.selected_status = payload.selected_status;
      } 

      if ('salesman_selected' in payload && payload.salesman_selected !== undefined) {
        state.client.filter.salesman_selected = payload.salesman_selected;
      }

      if ('client_type_selected' in payload && payload.client_type_selected != undefined) {
        state.client.filter.client_type_selected = payload.client_type_selected;
      } 

      if ('client_zone_selected' in payload && payload.client_zone_selected != undefined) { 
        state.client.filter.client_zone_selected = payload.client_zone_selected;
      }
      
      if(payload.search != undefined) state.client.filter.search = payload.search;
    },
    setCurrentPageSupplier: function (state, pagination) {
      state.supplier.pagination.page = pagination;
    },
    setFilterSupplier: function (state,payload) { 
      if (payload.selected_status != undefined) state.supplier.filter.selected_status = payload.selected_status;
      if(payload.search != undefined) state.supplier.filter.search = payload.search;

    },
    setCurrentPageCtaCteClient: function (state, pagination) {
      state.cta_cte_client.pagination.page = pagination;
    },
    setFilterCtaCteClient: function (state,payload) { 
      if ('salesman_selected' in payload && payload.salesman_selected !== undefined) {
        state.cta_cte_client.filter.salesman_selected = payload.salesman_selected;
      }

      if(payload.search != undefined) state.cta_cte_client.filter.search = payload.search;
    },
    setCurrentPageCtaCteSupplier: function (state, pagination) {
      state.cta_cte_supplier.pagination.page = pagination;
    },
    setFilterCtaCteSupplier: function (state,payload) { 
      if(payload.search != undefined) state.cta_cte_supplier.filter.search = payload.search;
    },
  },
  actions: {},
  modules: {},
});