<template>
  <div>
    <Title :pages="[{ icon: 'road', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="carrier_selected"
                    autocomplete="off"
                    data-vv-as="transportista"
                    name="transportista"
                    item-text="name"
                    item-value="id"
                    :items="carriers"
                    label="Transportista"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3 justify-content-end d-flex pt-0">
                  <v-btn color="blue-grey" class="mr-2" dark :loading="dialog_export" @click="exportFile()"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>

        <div class="mb-4" v-show="selected.length != 0 && update_access">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="border: 1px solid; border-radius: 100px; padding: 3px"
                color="blue-grey"
                :class="isActiveBlinking ? 'ml-3 button_blinking' : 'ml-3'"
                @click="toggleClass"
                v-bind="attrs"
                v-on="on"
              >
                more_vert
              </v-icon>
            </template>
            <v-list>
              <v-list-item dense @click="createSpent()">
                <v-list-item-title> Generar comprobante</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="{ item, isSelected, select }">
            <tr>
              <td>
                <v-checkbox v-if="item.spent == null" :input-value="isSelected" style="margin: 0px; padding: 0px" hide-details @change="select($event)" />
              </td>
              <td>#{{ item.id }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.carrier.name }}</td>
              <td>{{ item.zone != null ? item.zone.name : "" }}</td>
              <td>{{ item.pallet_amount }}</td>
              <td>{{ item.spent != null ? item.spent : null }}</td>

              <td class="text-right">{{ formatUtils.formatPrice(item.total_price) }}</td>

              <td class="text-center px-0">
                <v-tooltip bottom v-if="item.spent == null">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />

      <DialogGenerarSpent :invoice_to_generate="itemSpent" :open="dialog_spent" @closeDialog="dialog_spent = false" @updateDialog="filter" formBtn="Crear" @reload="filter" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";

import DialogGenerarSpent from "@/views/Spents/DialogGenerarSpent.vue";

export default {
  components: { Title, Modal, DialogDelete, DialogGenerarSpent },
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      formatUtils: formatUtils,
      parent: this,
      service: generalService,
      page_title: "Viajes",
      modal_title: "Viaje",
      mainItem: {
        id: "",
        date: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        carrier: null,
        zone: null,
        pallet_amount: 0,
        price: 0,
      },
      defaultItem: null,
      headers: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "",
        },
        {
          text: "Viaje N°",
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: "Fecha",
          align: "left",
          sortable: true,
          value: "date",
        },
        {
          text: "Transporte",
          align: "left",
          sortable: true,
          value: "carrier.name",
        },
        {
          text: "Zona",
          align: "left",
          sortable: true,
          value: "zone.name",
        },
        {
          text: "Pallets",
          align: "left",
          sortable: true,
          value: "pallets",
        },
        {
          text: "Comprobante",
          align: "left",
          sortable: true,
          value: "spent",
        },
        {
          text: "Total",
          align: "right",
          sortable: true,
          value: "total_price",
        },
        { text: "Accion", value: "", align: "center" },
      ],
      dialog_delete: false,
      dialog_spent: false,
      itemSpent: null,
      isActiveBlinking: true,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      desde_selected_open: false,
      hasta_selected_open: false,
      carrier_selected: null,
      carriers: [],
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.service.setSection(Constant.SECTION_SHIPPING);
    this.loadList(this.service);
    this.carrierLoad();
  },
  methods: {
    exportFile() {
      var param = this.getFilterParams();
      param["export"] = true;
      this.exportExcel(Constant.SECTION_SHIPPING + "/list", param, "Viajes.xlsx");
    },
    carrierLoad() {
      this.service.setSection(Constant.SECTION_CARRIER);
      this.service.filter({ enabled: true }).then((response) => {
        this.carriers = response.result;
      });
    },
    toggleClass() {
      this.isActiveBlinking = false;
    },
    createSpent() {
      //controlo que todos los viajes seleccionados sean del mismo transportista
      let carrier = this.selected[0].carrier;
      let allSameCarrier = this.selected.every((item) => item.carrier.id == carrier.id);
      if (allSameCarrier) {
        var date = new Date(new Date().toLocaleDateString("en-US", { timeZone: "America/Buenos_Aires" })).toISOString().slice(0, 10);

        this.itemSpent = {
          id: null,
          warehouse: null,
          stock_spent: false,
          voucher_number: null,
          cost_center: null,
          dolar_cotiz: null,
          supplier: carrier.supplier,
          receiver: null,
          cbte_tipo: null,
          cbte_fch: date,
          cbte_fch_serv_desde: date,
          cbte_fch_serv_hasta: date,
          fch_vto_pago: date,
          concepto: null,
          moneda: null,
          mon_cotiz: 1,
          items_custom: [],
          tributes: [],
          cbte_asoc: null,
          shippings: this.selected,
        };

        //por cada viaje agrego un item custom
        this.selected.forEach((item) => {
          this.itemSpent.items_custom.push({
            descripcion: "Viaje N° " + item.id + " - " + item.zone.name + " - " + item.pallet_amount + " Pallets - " + item.date,
            quantity: 1,
            price: item.total_price,
            product: null,
            iva: null,
            discount_imp: 0,
            discount_per: 0,
            sub_cost_center: null,
          });
        });
        this.dialog_spent = true;
      } else {
        this.$toast.error("Solo se pueden generar comprobantes de viajes del mismo transportista");
      }
    },
    getFilterParams() {
      let params = {
        from: this.desde_selected,
        to: this.hasta_selected,
        carrier: this.carrier_selected != null ? this.carrier_selected : null,
      };
      return params;
    },
    filter() {
      this.service.setSection(Constant.SECTION_SHIPPING);
      this.loadList(this.service, this.getFilterParams());
    },
    deleteItemConfirm() {
      this.service.setSection(Constant.SECTION_SHIPPING);
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
