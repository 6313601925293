<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"> </Title>

    <v-container fluid>
      <DialogLoading :dialog="loading_view_spent" text="Cargando..." />

      <ModalSpentPayment
        v-if="update_access"
        :spent="spent_to_pay"
        :openFromSpent="true"
        :formBtn="formBtn"
        :dialog="dialog_paid"
        @closeDialog="closPaymentDialog"
        @updateItem="
          (newItem) => {
            spent_to_pay = newItem;
          }
        "
      />

      <DialogLoading :dialog="loading_view_comprobante" text="Cargando..." />

      <DialogGenerarSpent title="Ver Comprobante" :isViewModal="true" :open="dialog_view_comprobante" @closeDialog="updateDialogComprobante" :item="mainItemComprobante" />

      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12 elevation-0" style="background: white">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="supplier_selected"
                    autocomplete="off"
                    data-vv-as="supplier_selected"
                    name="supplier_selected"
                    item-text="name"
                    item-value="id"
                    :items="suppliers"
                    label="Proveedor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-select v-model="expiration_status" :items="expirations_status" item-text="name" item-value="id" label="Estado" class="pt-0 mt-0" clearable></v-select>
                </div>
                <div class="col pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <div class="col-md-8 pb-0 pl-0">
            <v-btn small color="blue-grey" dark :loading="dialog_export" @click="exportFile()" class="mr-2"> Exportar <font-awesome-icon icon="download" /></v-btn>

            <v-chip outlined color="primary"
              >Total pendiente de pago:&nbsp;<span>{{ formatUtils.formatPrice(total) }}</span></v-chip
            >
          </div>
          <div class="col-md-4 pb-0">
            <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
          </div>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.cbte_fch }}</td>
              <td>
                <span @click="handleModal(props.item.id)" class="redirect-modal">{{ props.item.voucher_number_label }}</span>
              </td>
              <td>{{ props.item.receiver_name }}</td>
              <td>{{ props.item.mon_name }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_factura) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_pagado_de_factura) }}</td>
              <td class="text-right text-danger">{{ formatUtils.formatPrice(props.item.total_pendiente) }}</td>
              <td v-html="getStatus(props.item.expiration)"></td>

              <td class="text-center px-0 pr-3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="green" class="mr-2" @click="openPayment(props.item)"> payments </v-icon>
                  </template>
                  <span>Pagar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { formatUtils } from "@/libs/utils/formatUtils";
import { reportService } from "@/libs/ws/reportService";
import { paymentSpentService } from "@/libs/ws/paymentSpentService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import DialogGenerarSpent from "@/views/Spents/DialogGenerarSpent.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import ModalSpentPayment from "@/views/Spents/ModalSpentPayment.vue";
import { spentService } from "../../libs/ws/spentService";
import { generalService } from "../../libs/ws/generalService";

export default {
  components: { Title, DialogGenerarSpent, DialogLoading, ModalSpentPayment },
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      Constant: Constant,
      formatUtils: formatUtils,
      service: reportService,
      page_title: "Pagos pendientes",
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Comprobante", align: "left", sortable: true, value: "voucher_number_label" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
        { text: "Total comprobante", align: "center", sortable: true, value: "total_factura" },
        { text: "Total pagado", align: "center", sortable: true, value: "total_pagado_de_factura" },
        { text: "Total pendiente", align: "center", sortable: true, value: "total_pendiente" },
        { text: "Estado", align: "center", sortable: false, value: "" },
        { text: "Accion", value: "", align: "right" },
      ],
      list: [],
      total: 0,
      total_pago_a_cuenta: 0,
      suppliers: [],
      supplier_selected: null,
      expiration_status: null,
      expirations_status: [
        {
          id: "vencidas",
          name: "Vencidas",
        },
        {
          id: "no_vencidas",
          name: "No Vencidas",
        },
      ],
      dialog_view_comprobante: false,
      loading_view_comprobante: false,
      mainItemComprobante: {
        id: "",
        creation_date: "",
        cbte_tipo: "",
        emitter: "",
        receiver: "",
        imp_tot_conc: "",
        imp_neto: "",
        imp_total: "",
        dolar_cotiz: null,
      },
      spent_to_pay: null,
      dialog_paid: false,
      loading_view_spent: false,
    };
  },
  mounted() {
    this.loadList(this.service);
    this.loadSuppliers();
  },
  methods: {
    loadSuppliers() {
      generalService.setSection(Constant.SECTION_SUPPLIERS);
      generalService
        .filter()
        .then((response) => {
          this.suppliers = response.result;
        })
        .catch((e) => {
          console.log("suppliers.filter error");
          console.log(e);
        });
    },
    openPayment(item) {
      console.log("item", item);

      this.loading_view_spent = true;
      spentService
        .get(parseInt(item.id))
        .then((response) => {
          this.spent_to_pay = response.result;
          this.loading_view_spent = false;
          this.dialog_paid = true;
        })
        .catch((e) => {
          console.log("error service comprobante de pago cobro load.error");
          console.log(e);
          this.loading_view_spent = false;
        })
        .finally(() => {
          this.loading_view_spent = false;
        });
    },
    closPaymentDialog() {
      this.spent_to_pay = null;
      this.dialog_paid = false;
      this.loadList(this.service, this.getFilterParams());
    },
    updateDialogComprobante() {
      this.dialog_view_comprobante = false;
    },
    handleModal(id) {
      this.loading_view_comprobante = true;
      spentService
        .get(parseInt(id))
        .then((response) => {
          this.mainItemComprobante = response.result;
          this.loading_view_comprobante = false;
          this.dialog_view_comprobante = true;
        })
        .catch((e) => {
          console.log("error service voucher get load.error");
          console.log(e);
          this.loading_view_comprobante = false;
        })
        .finally(() => {
          this.loading_view_comprobante = false;
        });
    },
    getStatus(expiration) {
      if (expiration > 0) {
        return '<span class="por_vencer">Vence en ' + expiration + " días</span>";
      } else if (expiration == 0) {
        return '<span class="vence_hoy">Vence hoy</span>';
      } else if (expiration < 0) {
        return '<span class="vencida">Vencida hace ' + expiration * -1 + " días</span>";
      } else {
        return "";
      }
    },
    getTotal() {
      this.total = 0;
      if (this.list.length > 0) {
        this.list.forEach((element) => {
          this.total = (parseFloat(this.total) + parseFloat(element.total_pendiente)).toFixed(2);
        });
      }
    },
    getFilterParams() {
      const param = {
        supplier: this.supplier_selected != null ? this.supplier_selected.id : null,
        status: this.expiration_status != null ? this.expiration_status : null,
      };
      return param;
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .pendingVoucherSupplier(param)
        .then((response) => {
          this.list = response.result;
          //ordeno la lista por el campo expiration
          this.list.sort((a, b) => {
            return a.expiration - b.expiration;
          });
          this.loading = false;
          this.getTotal();
          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.total = 0;
          this.loading = false;
        });
    },
    filter() {
      const param = this.getFilterParams();
      this.loadList(this.service, param);
    },
    exportFile() {
      var param = this.getFilterParams();
      param["export"] = true;

      let fileName = "Comprobantes-Pendientes-Pago";

      if (this.supplier_selected != null) {
        fileName += "-" + this.supplier_selected.name;
      }
      fileName += ".xlsx";

      this.exportExcel(Constant.SECTION_REPORTS + "/supplier/pending-vouchers", param, fileName);
    },
  },
};
</script>
