var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Title',{attrs:{"pages":[
      { icon: 'file-invoice', path: '/reports/salesman', page: 'DEUDA POR VENDEDOR' },
      { icon: 'circle', page: _vm.page_title.toUpperCase() + ': ' + _vm.getName() },
    ]}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('div',{staticClass:"row mb-2 mt-0"},[(_vm.list_access)?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"col-md-12 elevation-0",staticStyle:{"background":"white"}},[_c('div',{staticClass:"row pt-5"},[_c('div',{staticClass:"col-md-3 pt-0"},[_c('v-combobox',{staticClass:"pt-0 mt-0",attrs:{"autocomplete":"off","item-text":"name","item-value":"id","items":_vm.clients,"label":"Cliente","clearable":""},model:{value:(_vm.client_selected),callback:function ($$v) {_vm.client_selected=$$v},expression:"client_selected"}})],1),_c('div',{staticClass:"col pt-0",staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.filter}},[_vm._v("Buscar")])],1)])])]):_vm._e()])]),(_vm.list_access)?_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"col-md-8 pb-0 pl-0"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue-grey","small":"","dark":"","loading":_vm.dialog_export},on:{"click":function($event){return _vm.exportFile()}}},[_vm._v(" Exportar "),_c('font-awesome-icon',{attrs:{"icon":"download"}})],1),_c('v-chip',{attrs:{"outlined":"","color":"primary"}},[_vm._v("Total pendiente de cobro: "),_c('span',[_vm._v(_vm._s(_vm.formatUtils.formatPrice(_vm.total)))])])],1),_c('div',{staticClass:"col-md-4 pb-0"},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando...","options":{
          descending: true,
          itemsPerPage: 15,
        },"footer-props":{
          itemsPerPageOptions: [5, 10, 15, 50, -1],
        }},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.cbte_fch))]),_c('td',[_c('span',{staticClass:"redirect-modal",on:{"click":function($event){return _vm.handleModal(props.item.id)}}},[_vm._v(_vm._s(props.item.voucher_number_label))])]),_c('td',[_vm._v(_vm._s(props.item.receiver_name))]),_c('td',[_vm._v(_vm._s(props.item.mon_name))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatUtils.formatPrice(props.item.total_factura)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatUtils.formatPrice(props.item.total_cobrado_de_factura)))]),_c('td',{staticClass:"text-right text-danger"},[_vm._v(_vm._s(_vm.formatUtils.formatPrice(props.item.total_pendiente)))]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.getStatus(props.item.expiration))}})])]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s("Busqueda sin resultados - " + _vm.search)+" ")])]},proxy:true}],null,false,3287452671)})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }