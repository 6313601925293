<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Periodo a imputar</h6>
      <p v-if="!isViewModal" class="text-body-2">Ingrese la fecha de creación de la orden de compra y la fecha de vencimiento del pago</p>
      <v-row class="mt-0">
        <v-col md="3">
          <DatePickerEsp
            :initToday="true"
            label="Fecha"
            :date="purchase_order.cbte_fch"
            :isRequired="true"
            name="cbte_fch"
            :isViewModal="isViewModal"
            @setDate="
              (date) => {
                purchase_order.cbte_fch = date;
              }
            "
          />
        </v-col>
        <v-col md="3">
          <DatePickerEsp
            :initToday="true"
            label="Fecha Vto. Pago"
            :date="purchase_order.fch_vto_pago"
            :isRequired="true"
            name="fch_vto_pago"
            :isViewModal="isViewModal"
            @setDate="
              (date) => {
                purchase_order.fch_vto_pago = date;
              }
            "
          />
        </v-col>
        <v-col md="3">
          <DatePickerEsp
            :initToday="true"
            label="Fecha estimada de entrega"
            :date="purchase_order.estimated_delvery_date"
            :isRequired="true"
            name="estimated_delvery_date"
            :isViewModal="isViewModal"
            @setDate="
              (date) => {
                purchase_order.estimated_delvery_date = date;
              }
            "
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DatePickerEsp from "@/components/DatePickerEsp.vue";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: { DatePickerEsp },
  props: {
    purchase_order: Object,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  watch: {},
  mounted() {},
  methods: {},
};
</script>
