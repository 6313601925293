var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Title',{attrs:{"pages":[{ icon: 'file-invoice', page: _vm.page_title.toUpperCase() }]}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('div',{staticClass:"row mb-2 mt-0"},[(_vm.list_access)?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"col-md-12 elevation-0",staticStyle:{"background":"white"}},[_c('div',{staticClass:"row pt-5"},[_c('div',{staticClass:"col-md-3 pt-0"},[_c('v-combobox',{staticClass:"pt-0 mt-0",attrs:{"autocomplete":"off","item-text":"name","item-value":"id","items":_vm.vendedores,"label":"Vendedor","clearable":""},on:{"change":function($event){return _vm.$store.commit('setFilterCtaCteClient', {
                      salesman_selected: _vm.salesman_selected
                    })},"clear":function($event){return _vm.$store.commit('setFilterCtaCteClient', { salesman_selected: null })}},model:{value:(_vm.salesman_selected),callback:function ($$v) {_vm.salesman_selected=$$v},expression:"salesman_selected"}})],1),_c('div',{staticClass:"col pt-0",staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.filter}},[_vm._v("Buscar")])],1)])])]):_vm._e()])]),(_vm.list_access)?_c('v-card',[_c('v-card-title',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue-grey mr-2 mt-3","small":"","dark":"","loading":_vm.dialog_export}},'v-btn',attrs,false),on),[_vm._v(" Exportar "),_c('font-awesome-icon',{staticClass:"ml-2",attrs:{"icon":"download"}})],1)]}}],null,false,358610672)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.exportListado}},[_c('v-list-item-title',[_vm._v("Exportar listado")])],1),_c('v-list-item',{on:{"click":function($event){_vm.dialog_export_ctacte = true}}},[_c('v-list-item-title',[_vm._v("Exportar Cuentas Corrientes")])],1)],1)],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},on:{"input":_vm.updateSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando...","options":{
          descending: true,
          itemsPerPage: 15,
        },"footer-props":{
          itemsPerPageOptions: [5, 10, 15, 50, -1],
        },"page":_vm.update_page},on:{"pagination":_vm.updatePagination,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(" "+_vm._s((_vm.pagination.page - 1) * _vm.pagination.itemsPerPage + props.index + 1)+" ")]),_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',[_vm._v(_vm._s(props.item.business_name))]),_c('td',[_vm._v(_vm._s(props.item.cuit))]),_c('td',{staticClass:"text-right"},[_c('span',{class:props.item.balance == 0 ? '' : props.item.balance > 0 ? 'bg-round-green' : 'bg-round-red'},[_vm._v(_vm._s(_vm.formatUtils.formatPrice(props.item.balance)))])]),_c('td',{staticClass:"text-center",staticStyle:{"min-width":"100px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.update_access)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue-grey"},on:{"click":function($event){return _vm.goToBalances(props.item)}}},on),[_vm._v(" description ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Ver Detalle Cta. Cte.")])])],1)])]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s("Busqueda sin resultados - " + _vm.search)+" ")])]},proxy:true}],null,false,572974524)})],1):_vm._e()],1),_c('DialogExportCtasCtes',{attrs:{"show":_vm.dialog_export_ctacte},on:{"confirm":_vm.exportCtasCtes,"cancel":function($event){_vm.dialog_export_ctacte = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }