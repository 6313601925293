<template>
  <div>
    <Title :pages="[{ icon: 'users', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-btn color="primary" class="mb-5" dark @click="updateDialog(true)">Crear {{ modal_title }}</v-btn>

      <v-btn class="ml-3 mb-5 text-white" color="blue-grey" @click="dialog_bulk = true">Importar Clientes</v-btn>

      <Modal v-if="create_access" :button_name="$t('Crear') + ' ' + modal_title" :modal_title="modal_title"
        :formBtn="formBtn" :dialog="dialog" :parent="parent" :service="service" @updateDialog="updateDialog"
        @loadList="loadList(service)" :item="mainItem" @updateItem="(newItem) => {
          mainItem = newItem;
        }
          " />

      <ModalBulk v-if="create_access" modal_title="Importar Clientes" :service="service" :formBtn="formBtn"
        :dialog="dialog_bulk" :mainItem="mainItem" @open="dialog_bulk = true" @close="dialog_bulk = false"
        @filter="loadList(service)" />

      <ModalInitialBalance :parent="parent" :modal_title="$t('Saldo inicial de') + ' ' + mainItem.name"
        :dialog="dialog_initial_balance" :item="mainItem" button_name="Saldo inicial" :formBtn="formBtn"
        :section="Constant.SECTION_CLIENTS" @updateDialog="updateDialog" @loadList="loadList(service)"
        @closeModal="closeModalInitialBalance" />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-6">
                <div class="col-md-4 pt-0">
                  <v-radio-group v-model="selected_status" row class="mt-0 mb-0" @change="
                    $store.commit('setFilterClient', {
                      selected_status: selected_status
                    })
                    ">
                    <v-radio label="Habilitado" :value="true"></v-radio>
                    <v-radio label="No habilitado" :value="false"></v-radio>
                    <v-radio label="Todos" value="all"></v-radio>
                  </v-radio-group>
                </div>
                <div class="col-12 col-md pt-0">
                  <v-combobox v-model="salesman_selected" autocomplete="off" data-vv-as="salesman_selected"
                    name="salesman_selected" item-text="name" item-value="id" :items="salesmans" label="Vendedor"
                    class="pt-0 mt-0" clearable @change="
                      $store.commit('setFilterClient', {
                        salesman_selected: salesman_selected
                      })
                      " @clear="$store.commit('setFilterClient', { salesman_selected: null })"></v-combobox>
                </div>
                <div class="col-12 col-md pt-0">
                  <v-combobox v-model="client_type_selected" autocomplete="off" data-vv-as="client_type_selected"
                    name="client_type_selected" item-text="name" item-value="id" :items="client_types" label="Tipo"
                    class="pt-0 mt-0" clearable @change="
                      $store.commit('setFilterClient', {
                        client_type_selected: client_type_selected
                      })
                      " @clear="$store.commit('setFilterClient', { client_type_selected: null })"></v-combobox>
                </div>
                <div class="col-12 col-md pt-0">
                  <v-combobox v-model="client_zone_selected" autocomplete="off" data-vv-as="client_zone_selected"
                    name="client_zone_selected" item-text="name" item-value="id" :items="zones_client" label="Zona"
                    class="pt-0 mt-0" clearable @change="
                      $store.commit('setFilterClient', {
                        client_zone_selected: client_zone_selected
                      })
                      " @clear="$store.commit('setFilterClient', { client_zone_selected: null })"></v-combobox>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="blue-grey mr-2" dark @click="exportToExcel"> Exportar </v-btn>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details @input="updateSearch"></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }" :page="update_page" @update:page="updatePage" @pagination="updatePagination" :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }">
          <template v-slot:item="props">
            <tr>
              <td>
                {{ (pagination.page - 1) * pagination.itemsPerPage + props.index + 1 }}
              </td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.business_name }}</td>
              <td>{{ props.item.cuit }}</td>
              <td>{{ props.item.telephone }}</td>
              <td>
                <div v-html="getEmailChip(props.item.invoiced_email)"></div>
              </td>
              <td>
                {{ props.item.price_list != null ? props.item.price_list.name : "-" }}
              </td>

              <td>
                {{ props.item.salesman != null ? props.item.salesman.name : "-" }}
              </td>

              <td>
                {{ props.item.type != null ? props.item.type.name : "-" }}
              </td>
              <td>
                {{ props.item.zone != null ? props.item.zone.name : "-" }}
              </td>
              <td class="text-right">
                <span
                  :class="props.item.balance == 0 ? '' : props.item.balance > 0 ? 'bg-round-green' : 'bg-round-red'">{{
                    formatUtils.formatPrice(props.item.balance) }}</span>
              </td>
              <td class="text-center">
                {{ props.item.enabled ? "SI" : "NO" }}
              </td>
              <td class="text-right" style="min-width: 200px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue-grey" class="mr-2"
                      @click="goToBalances(props.item)"> description </v-icon>
                  </template>
                  <span>Ver Cta. Cte.</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="orange" class="mr-2"
                      @click="goToComprobantesPendientes(props.item)"> payments </v-icon>
                  </template>
                  <span>Comprobantes pendientes</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="grey" class="mr-2"
                      @click="goToDeliveryPoint(props.item)"> local_shipping </v-icon>
                  </template>
                  <span>Puntos de entrega</span>
                </v-tooltip>

                <!--<v-tooltip bottom v-if="props.item.initial_balance == null">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="purple" class="mr-2" @click="editInicialBalanceItem(props.item)"> request_quote </v-icon>
                  </template>
                  <span>Editar Saldo inicial</span>
                </v-tooltip>-->

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2"
                      @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)"> remove_red_eye
                    </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2"
                      @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
        @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { formatUtils } from "@/libs/utils/formatUtils";
import { clientsService } from "@/libs/ws/clientsService";
import { generalService } from "@/libs/ws/generalService";
import { salesmanService } from "@/libs/ws/salesmanService";

import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import ModalBulk from "./ModalBulk.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ModalInitialBalance from "@/components/ModalInitialBalance.vue";

export default {
  components: { Title, Modal, DialogDelete, ModalInitialBalance, ModalBulk },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      Constant: Constant,
      dialog_initial_balance: false,
      dialog_bulk: false,
      parent: this,
      formatUtils: formatUtils,
      service: clientsService,
      page_title: "Clientes",
      modal_title: "Cliente",
      selected_status: this.$store.state.client.filter.selected_status,
      salesman_selected: this.$store.state.client.filter.salesman_selected,
      client_type_selected: this.$store.state.client.filter.client_type_selected,
      client_zone_selected: this.$store.state.client.filter.client_zone_selected,
      search: this.$store.state.client.filter.search,
      client_types: [],
      zones_client: [],
      salesmans: [],
      mainItem: {
        id: "",
        name: "",
        business_name: "",
        cuit: "",
        telephone: "",
        invoiced_email: "",
        external_reference: "",
        invoice_expiration: "",
        address: "",
        price_list: null,
        salesman: null,
        enabled: true,
        type: null,
        zone: null,
      },
      defaultItem: null,
      headers: [
        { text: "Numero", value: "number" },
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Razón social",
          align: "left",
          sortable: true,
          value: "business_name",
        },
        {
          text: "CUIT",
          align: "left",
          sortable: true,
          value: "cuit",
        },

        {
          text: "Telefono",
          align: "left",
          sortable: true,
          value: "telephone",
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "invoiced_email",
        },
        {
          text: "Lista de precios",
          align: "left",
          sortable: true,
          value: "price_list.name",
        },
        {
          text: "Vendedor",
          align: "left",
          sortable: true,
          value: "salesman.name",
        },
        {
          text: "Tipo",
          align: "left",
          sortable: true,
          value: "type.name",
        },
        {
          text: "Zona",
          align: "left",
          sortable: true,
          value: "zone.name",
        },
        { text: "Saldo Acumulado", value: "balance", align: "center" },
        { text: "Habilitado", value: "enabled", align: "center" },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      dialog_delete: false,
      update_page: 1
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service);
    this.loadZonesClient();
    this.loadClientTypes();
    this.loadVendedores();
  },
  methods: {
    updateSearch(value) {
      this.search = value;
      this.$store.commit('setFilterClient', { search: value });
    },
    updatePage(page) {
      this.$store.commit('setCurrentPageClient', page);
      this.update_page = page;
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .filter(param)
        .then((response) => {
          if (this.$store.state.client.pagination.page > 1) {
            this.update_page = this.$store.state.client.pagination.page;
          }

          this.list = response.result;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    loadVendedores() {
      salesmanService
        .filter({ enabled: true })
        .then((response) => {
          this.salesmans = response.result;
        })
        .catch((e) => { });
    },
    exportToExcel() {
      if (this.loading) return;
      this.loading = true;
      let param = this.getFilterParams();

      this.service
        .export_filter(param)
        .then((response) => {
          if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
            this.$toast.error(response.msg, "Error");
            this.loading = false;
            return;
          }

          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "clients.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          link.click();
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.export_filter clients error");
          console.log(e);
          this.loading = false;
        });
    },
    loadZonesClient() {
      generalService.setSection(Constant.SECTION_CLIENT_ZONE);
      generalService
        .filter()
        .then((response) => {
          this.zones_client = response.result;
        })
        .catch((e) => {
          console.log("zones_client.filter error");
          console.log(e);
        });
    },
    loadClientTypes() {
      generalService.setSection(Constant.SECTION_CLIENT_TYPE);
      generalService
        .filter()
        .then((response) => {
          this.client_types = response.result;
        })
        .catch((e) => {
          console.log("client_types.filter error");
          console.log(e);
        });
    },
    goToComprobantesPendientes(item) {
      this.$router.push("/reports/client/pending-vouchers/" + item.id);
    },
    getEmailChip(emails) {
      var emails_arr = this.parsearEmails(emails);
      if (emails_arr.length > 1) {
        return emails_arr[0] + '<span class="chip ml-1">+' + (emails_arr.length - 1) + "</span>";
      } else {
        return emails_arr[0];
      }
    },
    parsearEmails(stringEmails) {
      try {
        if (stringEmails == null) {
          return [];
        }
        // Comprueba si el string de correos electrónicos está vacío
        if (!stringEmails.trim()) {
          return [];
        }
        // Divide el string por comas y/o espacios y elimina posibles espacios adicionales
        const emailsArray = stringEmails.split(/[, ]+/).map((email) => email.trim());

        // Filtra los elementos vacíos que podrían haberse generado por espacios adicionales
        const filteredEmailsArray = emailsArray?.filter((email) => email !== "");

        return filteredEmailsArray;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    goToBalances(item) {
      this.$router.push(Constant.SECTION_CLIENTS_BALANCES + "/" + item.id);
    },
    goToDeliveryPoint(item) {
      this.$router.push(this.$route.path + "/delivery-points/" + item.id);
    },
    editInicialBalanceItem(item) {
      this.errors.clear();
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = Object.assign({}, item);
      this.dialog_initial_balance = true;
    },
    closeModalInitialBalance() {
      this.dialog_initial_balance = false;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    getFilterParams() {
      const param = {
        enabled: this.selected_status,
        type: this.client_type_selected != null ? this.client_type_selected.id : null,
        zone: this.client_zone_selected != null ? this.client_zone_selected.id : null,
        salesman: this.salesman_selected != null ? this.salesman_selected.id : null,
      };
      return param;
    },
  },
};
</script>
