<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12 elevation-0" style="background: white">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-combobox v-model="salesman_selected" autocomplete="off" item-text="name" item-value="id"
                    :items="vendedores" label="Vendedor" class="pt-0 mt-0" clearable @change="
                      $store.commit('setFilterCtaCteClient', {
                        salesman_selected: salesman_selected
                      })
                      "
                      @clear="$store.commit('setFilterCtaCteClient', { salesman_selected: null })"></v-combobox>
                </div>

                <div class="col pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="blue-grey mr-2 mt-3" small dark v-bind="attrs" v-on="on" :loading="dialog_export"> Exportar
                <font-awesome-icon class="ml-2" icon="download" /></v-btn>
            </template>
            <v-list>
              <v-list-item @click="exportListado">
                <v-list-item-title>Exportar listado</v-list-item-title>
              </v-list-item>
              <v-list-item @click="dialog_export_ctacte = true">
                <v-list-item-title>Exportar Cuentas Corrientes</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details @input="updateSearch"></v-text-field>
        </v-card-title>

        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }" @pagination="updatePagination" :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
            :page="update_page"
            @update:page="updatePage"
            >
          <template v-slot:item="props">
            <tr>
              <td>
                {{ (pagination.page - 1) * pagination.itemsPerPage + props.index + 1 }}
              </td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.business_name }}</td>
              <td>{{ props.item.cuit }}</td>
              <td class="text-right">
                <span
                  :class="props.item.balance == 0 ? '' : props.item.balance > 0 ? 'bg-round-green' : 'bg-round-red'">{{
                    formatUtils.formatPrice(props.item.balance) }}</span>
              </td>
              <td class="text-center" style="min-width: 100px">
              <!--<v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-if="update_access" v-on="on" small color="blue-grey" class="mr-2" @click="goToComprobantesPendientes(props.item)"> description </v-icon>
                    </template>
                    <span>Comprobantes pendientes</span>
                  </v-tooltip>-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue-grey" class="mr-2"
                      @click="goToBalances(props.item)"> description </v-icon>
                  </template>
                  <span>Ver Detalle Cta. Cte.</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <DialogExportCtasCtes :show="dialog_export_ctacte" @confirm="exportCtasCtes"
      @cancel="dialog_export_ctacte = false" />
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { formatUtils } from "@/libs/utils/formatUtils";
import { clientsService } from "@/libs/ws/clientsService";
import { salesmanService } from "@/libs/ws/salesmanService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import DialogExportCtasCtes from "@/components/Reports/DialogExportCtasCtes.vue";
export default {
  components: { Title, DialogExportCtasCtes },
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      Constant: Constant,
      formatUtils: formatUtils,
      service: clientsService,
      dialog_export_ctacte: false,
      page_title: "Cta. Cte. Clientes",
      headers: [
        { text: "Numero", value: "number" },
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Razón social",
          align: "left",
          sortable: true,
          value: "business_name",
        },
        {
          text: "CUIT",
          align: "left",
          sortable: true,
          value: "cuit",
        },
        { text: "Saldo Acumulado", value: "balance", align: "center" },
        { text: "Accion", value: "", align: "center" },
      ],
      vendedores: [],
      list: [],
      salesman_selected: this.$store.state.cta_cte_client.filter.salesman_selected,
      search: this.$store.state.cta_cte_client.filter.search,
      update_page: 1
    };
  },
  mounted() {
    this.loadVendedores();
    this.loadList(this.service);
  },
  methods: {
    updateSearch(value) {
      this.search = value;
      this.$store.commit('setFilterCtaCteClient', { search: value });
    },
    updatePage(page) {
      this.$store.commit('setCurrentPageCtaCteClient', page);
      this.update_page = page;
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .filter(param)
        .then((response) => {
          if(this.$store.state.cta_cte_client.pagination.page > 1) {
            this.update_page = this.$store.state.cta_cte_client.pagination.page;
          }

          this.list = response.result;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },

    loadVendedores() {
      salesmanService
        .filter({ enabled: true })
        .then((response) => {
          this.vendedores = response.result;
        })
        .catch((e) => { });
    },
    getFilterParams() {
      const param = {
        salesman: this.salesman_selected != null ? this.salesman_selected.id : null,
      };
      return param;
    },
    filter() {
      const param = this.getFilterParams();
      this.loadList(this.service, param);
    },
    goToBalances(item) {
      this.$router.push("/" + Constant.SECTION_CLIENTS_BALANCES + "/" + item.id);
    },
    goToComprobantesPendientes(item) {
      this.$router.push("/reports/client/pending-vouchers/" + item.id);
    },
    exportListado() {
      this.exportExcel(Constant.SECTION_REPORTS + "/cuentas-por-cobrar", {}, "Cuentas-por-cobrar.xlsx");
    },
    exportCtasCtes(params) {
      params["export"] = true;
      this.exportExcel(Constant.SECTION_CLIENTS_BALANCES + "/list", params, "Cuentas-Corrientes-Clientes.xlsx");
      this.dialog_export_ctacte = false;
    },
    exportVoucherPendientes() {
      var param = {};
      param["export"] = true;
      this.exportExcel(Constant.SECTION_REPORTS + "/client/pending-vouchers", param, "Comprobantes-Pendientes-Cobro.xlsx");
    },
  },
};
</script>
