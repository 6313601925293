<template>
  <v-card v-if="invoice.emitter != null && invoice.emitter.iva_condition != undefined && invoice.emitter.iva_condition.id == emmiterService.CONDITION_IVA_MONO" elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Información del monotributo</h6>
      <p class="text-body-2">Información relevante sobre los maximos permitidos de facturación</p>
      <v-row>
        <v-col>
          <v-chip outlined color="primary" class="mr-2 mb-2">Max. permitido: {{ formatUtils.formatPrice(invoice.emitter.allowed_month_limit * 12 - invoice.emitter.last_year_in_a_row) }} </v-chip>
          <v-chip outlined class="mr-2 mb-2">Limite mensual sugerido: {{ formatUtils.formatPrice(invoice.emitter.allowed_month_limit) }} </v-chip>
          <v-chip outlined class="mr-2 mb-2">Facturado ultimo 12 meses: {{ formatUtils.formatPrice(invoice.emitter.last_year_in_a_row) }} </v-chip>
          <v-chip outlined class="mr-2 mb-2">Tope categoria de monotributo: {{ formatUtils.formatPrice(invoice.emitter.allowed_month_limit * 12) }} </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { emmiterService } from "@/libs/ws/emmiterService";
import { formatUtils } from "@/libs/utils/formatUtils";
export default {
  components: {},
  props: { invoice: Object },
  data: () => ({
    emmiterService: emmiterService,
    formatUtils: formatUtils,
  }),
  watch: {},
  mounted() {},
  methods: {},
};
</script>
