<template>
  <div>
    <v-dialog v-model="open" max-width="1200" persistent>
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-card-title class="headline mb-4">{{ title }}</v-card-title>

        <v-card-text>
          <div class="row">
            <div class="col-md-12">
              <!--Informacion Monotribto-->
              <MonotributoInfo :sale_order="sale_order" />

              <!--Datos generales-->
              <DatosGenerales
                :order_to_cancel="order_to_cancel"
                :sale_order="sale_order"
                :loading_combos="loading_combos"
                :open="open"
                :settings="settings"
                :validator="$validator"
                :receiver="receiver"
                :emitter="emitter"
              />

              <!--Periodo a imputar-->
              <Periodo :sale_order="sale_order" :validator="$validator" />

              <!-- Compra con o sin stock -->
              <ConStock :invoice="sale_order" :validator="$validator" :open="open" :isViewModal="isViewModal" />

              <!-- Items Voucher -->
              <ItemsSaleOrder :sale_order="sale_order" :open="open" :loading_combos="loading_combos" :validator="$validator" />

              <!--Totales-->
              <Observation :item="sale_order" :isViewModal="isViewModal" />

              <v-row>
                <!--Info Extra-->
                <InfoExtra :dialog="open" :item="sale_order" minHeight="300px" :show_cost_center="true" :isViewModal="isViewModal" />
                <!--Totales-->
                <Totales
                  :sale_order="sale_order"
                  :imp_iva="imp_iva"
                  :imp_neto="imp_neto"
                  :imp_tot_conc="imp_tot_conc"
                  :imp_op_ex="imp_op_ex"
                  :imp_total="imp_total"
                  :imp_trib="imp_trib"
                  :imp_discount="imp_discount"
                  :isViewModal="isViewModal"
                />
              </v-row>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="close()"> Cancelar </v-btn>

          <div v-if="!isViewModal">
            <v-btn color="green darken-1" text @click="generateSaleOrder" :loading="loading"> {{ sale_order.id == "" ? "Generar" : "Editar" }} </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogLoading :dialog="dialog_loading" text="Cargando..." />
  </div>
</template>
<script>
import { saleOrderService } from "@/libs/ws/saleOrderService";
import { emmiterService } from "@/libs/ws/emmiterService";
import { billingUtils } from "@/libs/utils/billingUtils";

import { formatUtils } from "@/libs/utils/formatUtils";
import DialogLoading from "@/components/DialogLoading.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";

import DatosGenerales from "@/views/SaleOrder/SaleOrderComponent/DatosGenerales.vue";
import MonotributoInfo from "@/views/SaleOrder/SaleOrderComponent/MonotributoInfo.vue";
import ItemsSaleOrder from "@/views/SaleOrder/SaleOrderComponent/ItemsSaleOrder.vue";
import Totales from "@/views/SaleOrder/SaleOrderComponent/Totales.vue";
import Periodo from "@/views/SaleOrder/SaleOrderComponent/Periodo.vue";
import ConStock from "@/components/Comprobantes/ConStock.vue";

import InfoExtra from "@/components/Comprobantes/InfoExtra.vue";
import Observation from "@/components/Comprobantes/Observation.vue";
export default {
  components: { DialogLoading, DatosGenerales, Periodo, MonotributoInfo, ItemsSaleOrder, InfoExtra, Totales, Observation, ConStock },
  mixins: [SettingsMixin],
  props: {
    title: String,
    checkbox_label: String,
    open: Boolean,
    isViewModal: {
      default: false,
      type: Boolean,
    },
    order_to_cancel: {
      default: null,
      type: Object,
    },
    invoice_to_generate: {
      default: null,
      type: Object,
    },
    closeDialog: Function,
    reload: Function,
    cancel: Function,
    item: Object,
    updateMainItem: Function,
    receiver: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Object,
    },
    emitter: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Object,
    },
  },
  data: () => ({
    emmiterService: emmiterService,
    saleOrderService: saleOrderService,
    loading: false,
    dialog_loading: false,
    formatUtils: formatUtils,
    checkbox: false,
    ivas: [],

    sale_order: {
      id: "",
      sub_cost_center: null,
      warehouse: null,
      dolar_cotiz: null,
      emitter: null,
      receiver: null,
      cbte_fch: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),

      fch_vto_pago: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      estimated_delvery_date: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      // concepto: null,
      moneda: null,
      mon_cotiz: 1,
      items_custom: [],
      tributos: [],
      observation: "",
    },
    //Se separaron de sale_order para poder usar el watch sin hacer un bucle infinito
    imp_total: 0,
    imp_neto: 0,
    imp_tot_conc: 0,
    imp_op_ex: 0,
    imp_iva: 0,
    imp_trib: 0,
    imp_discount: 0,
    defaultSaleOrder: null,
    loading_combos: {
      cbte_tipos_invoice: false,
      emitters: false,
      receivers: false,
      monedas: false,
      ivas: false,
    },
  }),
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  watch: {
    open: function (val) {
      if (this.item && this.item.id != "") {
        this.sale_order = JSON.parse(JSON.stringify(this.item));

        this.imp_total = this.item.imp_total;
        this.imp_neto = this.item.imp_neto;
        this.imp_tot_conc = this.item.imp_tot_conc;
        this.imp_op_ex = this.item.imp_op_ex;
        this.imp_iva = this.item.imp_iva;
        this.imp_trib = this.item.imp_trib;
        this.imp_discount = this.item.imp_discount;
        this.sale_order.items_custom = this.item.items_custom;
      }
    },

    sale_order: {
      handler: function (val) {
        this.calculateTotal();
      },
      deep: true,
    },
    loading_combos: {
      handler: function (val) {
        if (this.isFinishedLoadingCombos()) {
          this.dialog_loading = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultSaleOrder = JSON.parse(JSON.stringify(this.sale_order));
  },
  methods: {
    isFinishedLoadingCombos() {
      return this.loading_combos["emitters"] == false && this.loading_combos["receivers"] == false && this.loading_combos["monedas"] == false && this.loading_combos["ivas"] == false;
    },
    selectComboId(combo, id, property_id = "id") {
      if (combo != null && combo.length > 0) {
        for (let i = 0; i < combo.length; i++) {
          if (combo[i][property_id] == id) {
            return combo[i];
          }
        }
      }
      return null;
    },
    close() {
      this.sale_order = JSON.parse(JSON.stringify(this.defaultSaleOrder));

      this.$emit("updateMainItem");
      this.$emit("closeDialog");
    },
    generateSaleOrder(event) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          let data = this.getInvoiceParams();
          if (data.imp_total <= 0) {
            this.$toast.error("El importe del comprobante debe ser mayor a 0.", "Error");
            return;
          }
          this.loading = true;

          if (this.sale_order && this.sale_order.id > 0) {
            saleOrderService
              .update(data)
              .then((response) => {
                if (response.status) {
                  this.$toast.success("La Orden de Venta se editó correctamente", "OK");

                  this.sale_order = JSON.parse(JSON.stringify(this.defaultSaleOrder));
                  this.$emit("reload");
                  this.close();
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    if (Object.prototype.hasOwnProperty.call(response, "message")) {
                      this.$toast.error(response.message, "Error");
                    } else {
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            saleOrderService
              .create(data)
              .then((response) => {
                if (response.status) {
                  this.$toast.success("La Orden de Venta generó correctamente", "OK");

                  this.sale_order = JSON.parse(JSON.stringify(this.defaultSaleOrder));
                  this.$emit("reload");
                  this.close();
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    if (Object.prototype.hasOwnProperty.call(response, "message")) {
                      this.$toast.error(response.message, "Error");
                    } else {
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          this.$toast.error("Complete los campos requeridos", "Error");
        }
      });
    },
    getInvoiceParams() {
      let data = {
        warehouse_id: this.isProductCompany() ? this.sale_order.warehouse.id : null,

        id: this.sale_order.id,
        emitter_id: this.sale_order.emitter.id,
        receiver_id: this.sale_order.receiver.id,
        cbte_fch: this.sale_order.cbte_fch,
        cbte_fch_serv_desde: this.sale_order.cbte_fch_serv_desde,
        cbte_fch_serv_hasta: this.sale_order.cbte_fch_serv_hasta,
        fch_vto_pago: this.sale_order.fch_vto_pago,
        estimated_delvery_date: this.sale_order.estimated_delvery_date,
        mon_id: this.sale_order.moneda.Id,
        mon_cotiz: this.sale_order.moneda.Id == "PES" ? 1 : this.sale_order.mon_cotiz,
        dolar_cotiz: this.sale_order.dolar_cotiz,
        observation: this.sale_order.observation,
        sub_cost_center_id: this.sale_order.sub_cost_center != null ? this.sale_order.sub_cost_center.id : null,

        imp_total: parseFloat(this.imp_total).toFixed(2), //Para enviar el mismo
        imp_neto: parseFloat(this.imp_neto).toFixed(2), //Para enviar el mismo
        imp_tot_conc: parseFloat(this.imp_tot_conc).toFixed(2), //Para enviar el mismo
        imp_iva: parseFloat(this.imp_iva).toFixed(2), //Para enviar el mismo
        imp_op_ex: parseFloat(this.imp_op_ex).toFixed(2), //Para enviar el mismo
        imp_trib: parseFloat(this.imp_trib).toFixed(2), //Para enviar el mismo
        imp_discount: parseFloat(this.imp_discount).toFixed(2), //Para enviar el mismo

        items_custom_arr: [],
        sale_order_number: this.sale_order.sale_order_number,
      };

      this.sale_order.items_custom.forEach((item) => {
        const item_data = this.calculateCustomeItemTotals(item);
        data.items_custom_arr.push({
          id: item.id,
          desc: item.descripcion,
          iva_id: item.iva != null ? item.iva.Id : null,
          iva_desc: item.iva != null ? item.iva.Desc : "",
          imp_neto: item_data.imp_neto,
          imp_iva: item_data.imp_iva,
          price: item.price,
          product_presentation: item.product_presentation,
          quantity: item.quantity,
          discount_per: parseFloat(item.discount_per),
          discount_imp: item.discount_imp,
        });
      });

      return data;
    },
    calculateCustomeItemTotals(item) {
      if (item.price == null || item.quantity == null)
        return {
          imp_neto: 0,
          imp_iva: 0,
        };

      let imp_neto = formatUtils.formatPriceNumber(item.quantity * item.price - item.discount_imp);
      const iva_id = item.iva != null ? item.iva.Id : null;
      let imp_iva =
        saleOrderService.NoIVAList().includes(iva_id) || iva_id == null ? 0.0 : (formatUtils.formatPriceNumber(imp_neto) * formatUtils.formatPriceNumber(item.iva.Desc.replace("%", ""))) / 100;
      return {
        imp_neto: imp_neto,
        imp_iva: formatUtils.formatPriceNumber(imp_iva),
        discount_imp: item.discount_imp,
        total: formatUtils.formatPriceNumber(imp_neto) + formatUtils.formatPriceNumber(imp_iva),
      };
    },

    calculateTotal() {
      this.imp_total = 0.0;
      this.imp_neto = 0.0;
      this.imp_tot_conc = 0.0;
      this.imp_iva = 0.0;
      this.imp_trib = 0.0;
      this.imp_op_ex = 0.0;
      this.imp_discount = 0.0;

      this.sale_order.items_custom.forEach((item) => {
        //Update discount_imp
        if (item.price != null && item.quantity != null && item.discount_per != null) item.discount_imp = (item.price * item.quantity * item.discount_per) / 100;
        else item.discount_imp = 0;

        let item_totales_obj = this.calculateCustomeItemTotals(item);
        if (this.sale_order.emitter != null && this.sale_order.emitter.iva_condition.id != emmiterService.CONDITION_IVA_MONO) {
          item_totales_obj["iva_id"] = item.iva == null ? null : item.iva.Id;

          let imp_neto = billingUtils.getTotalFor("imp_neto", item_totales_obj);
          let imp_tot_conc = billingUtils.getTotalFor("imp_tot_conc", item_totales_obj);
          let imp_op_ex = billingUtils.getTotalFor("imp_op_ex", item_totales_obj);
          let imp_iva = item_totales_obj.imp_iva;

          this.imp_tot_conc += imp_tot_conc;
          this.imp_iva += imp_iva;
          this.imp_op_ex += imp_op_ex;
          this.imp_neto += imp_neto;
          this.imp_total += imp_neto + imp_tot_conc + imp_op_ex + imp_iva;
        } else {
          this.imp_neto += item_totales_obj.total;
          this.imp_total += item_totales_obj.total;
        }
      });
    },
  },
};
</script>
