const STATE_PENDING = 0;
const STATE_FINALISHED = 1;
const STATE_CANCELED = 2;
const STATE_PARTIALLY = 3;
const STATE_PENDING_NAME = "Pendiente";
const STATE_FINALISHED_NAME = "Finalizada";
const STATE_CANCELED_NAME = "Cancelada";
const STATE_PARTIALLY_NAME = "Factura parcialmente";


export const ordersState= {
  STATE_PENDING: STATE_PENDING,
  STATE_FINALISHED: STATE_FINALISHED,
  STATE_CANCELED: STATE_CANCELED,
  STATE_PARTIALLY: STATE_PARTIALLY,
  STATE_PENDING_NAME: STATE_PENDING_NAME,
  STATE_FINALISHED_NAME: STATE_FINALISHED_NAME,
  STATE_CANCELED_NAME: STATE_CANCELED_NAME,
  STATE_PARTIALLY_NAME: STATE_PARTIALLY_NAME,
};