<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Periodo a imputar</h6>
      <p v-if="!isViewModal" class="text-body-2">Ingrese la fecha de emisión de la factura y la fecha de vencimiento del cobro</p>
      <v-row class="mt-0">
        <v-col md="3">
          <DatePickerEsp          
            :initToday="true"
            label="Fecha"
            :date="invoice.cbte_fch"
            :isRequired="true"
            name="cbte_fch"
            :isViewModal="isViewModal"
            @setDate="
              (date) => {
                invoice.cbte_fch = date;
              }
            "
          />
        </v-col>
        <v-col md="3" v-if="(concepto_selected != null && concepto_selected.Id != 1) || concepto_selected == null"
          ><!--PRODUCTO CONCEPTO-->
          <DatePickerEsp            
            :initToday="true"
            label="Periodo desde (Min: -10 días de fecha de emisión)"
            :date="invoice.cbte_fch_serv_desde"
            :isRequired="true"
            name="cbte_fch_serv_desde"
            :isViewModal="isViewModal"
            @setDate="
              (date) => {
                invoice.cbte_fch_serv_desde = date;
              }
            "
          />
        </v-col>
        <v-col md="3" v-if="(concepto_selected != null && concepto_selected.Id != 1) || concepto_selected == null">
          <DatePickerEsp            
            :initToday="true"
            label="Periodo hasta (Max: +10 días de fecha de emisión)"
            :date="invoice.cbte_fch_serv_hasta"
            :isRequired="true"
            name="cbte_fch_serv_hasta"
            :isViewModal="isViewModal"
            @setDate="
              (date) => {
                invoice.cbte_fch_serv_hasta = date;
              }
            "
          />
        </v-col>
        <v-col md="3">
          <DatePickerEsp            
            :initToday="true"
            label="Fecha Vto. Pago"
            :date="invoice.fch_vto_pago"
            :isRequired="true"
            name="fch_vto_pago"
            :isViewModal="isViewModal"
            @setDate="
              (date) => {
                invoice.fch_vto_pago = date;
              }
            "
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DatePickerEsp from "@/components/DatePickerEsp.vue";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: { DatePickerEsp },
  props: {
    invoice: Object,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    concepto_selected: null,
  }),
  watch: {
    invoice: {
      handler: function (newValue) {
        if ((this.concepto_selected == null && newValue.concepto != null) || (newValue.concepto != null && this.concepto_selected.Id != newValue.concepto.Id)) {
          this.concepto_selected = newValue.concepto;
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {},
};
</script>
